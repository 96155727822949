import React, { useMemo, useCallback } from 'react'
import { BenefitsCategoryWrapperList, BenefitChoices, BenefitsCategoryCode } from '../../../services/enrollment/enrollment-types'
import { SubGroupBenefitsField } from '../SubGroupBenefits'
import FooterContent from './FooterContent'
import { useGetAllSelfBenefits, useGetEnrollmentActivePeriod, useGetEnrollmentSelectPeriod } from '../../../services/enrollment/enrollment-query'
import styled from '@emotion/styled'
import Text from '../../../components/common/Text'
import color from '../../../constant/colors'
import { FamilyCard } from './component';
import { useGetRelativeBenefit } from '../../../services/relative/relative-query';
import { useVisibility } from '../../../utils/custom-hook'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../redux-store'
import Button from '../../../components/common/Button'
import { mobile, useScreen } from '../../../utils/responsive-helper'
import { useGetGroupNumber } from '../../../services/enrollment/enrollment-query'
import { useRouter } from '../../../utils/helper'


const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;

  ${mobile} {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
`
const FamilyList = styled.div`
  display: grid;
  column-gap: 16px;
  row-gap: 16px;
  grid-template-columns: repeat(2, 1fr);

  ${mobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`
const GroupBenefits = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`
const ButtonInsurance = styled(Button)`
  width: fit-content;
  align-self: flex-end;

  ${mobile} {
    align-self: center;
  }
`
const ConditonLayout = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`

const required = (value: string) => (value ? undefined : 'error.required')

const SelectBenefit = () => {
  const { query } = useRouter<{ id: number }>()
  const { isMobile } = useScreen()
  const { data: enrollmentPeriod, isLoading: isLoadingPresentPeriod, isFetched: isFetchedEnrollmentPeriod } = useGetEnrollmentSelectPeriod()
  const { benefitYearId } = enrollmentPeriod || {}
  const { data: enrollmentActivePeriod } = useGetEnrollmentActivePeriod()
  const { data, isStale } = useGetAllSelfBenefits(benefitYearId, query.id)

  const isNotFoundBenefitYear = useMemo(() => {
    if (isFetchedEnrollmentPeriod && enrollmentPeriod) return false

    return true
  }, [enrollmentPeriod, isFetchedEnrollmentPeriod])

  const { data: RelativeData } = useGetRelativeBenefit(isNotFoundBenefitYear ? enrollmentActivePeriod?.benefitYearId : enrollmentPeriod?.benefitYearId);
  const { data: groupNumber } = useGetGroupNumber();

  const filterRelativeData = useMemo(() => {
    return RelativeData?.filter((item) => !['มารดา', 'บิดา'].includes(item.relativeType.name));
  }, [RelativeData]);

  const onClickOpenPDF = useCallback(() => {
    window.open(`https://staticwebsite0fh0sx.blob.core.windows.net/app/insurance-plan/BST_Insurance_Pack_${groupNumber?.insuranceGroup?.split(" ").join("_")}.pdf`)
  }, [groupNumber]);

  const benefit: BenefitsCategoryWrapperList = useMemo(() => {
    return {
      benefitsCategoryId: 1,
      benefitsCategoryName: 'เลือกประเภท',
      benefitsCategoryCode: BenefitsCategoryCode.SELF,
      choices: [
        {
          id: 1,
          choicesName: 'แผนที่ 1',
          choicesDescription: `<div style="min-height: 150px;text-align: center;"><p style="color: #363640; font-weight: 700; text-align: center; padding: 8px 16px; font-size: 18px; margin: 0;">รายละเอียด</p><div style="color: #363640; text-align: center; padding-bottom: 16px"><p style="margin: 0; font-size: 16px">ความคุ้มครองตามมาตรฐานของบริษัท</p></div></div>`,
          taggedPrice: 12000,
          defaultCoinValue: 12000,
          consentDetails: '',
          selected: false,
          isDefault: true,
        },
        {
          id: 2,
          choicesName: 'แผนที่ 2',
          choicesDescription: `<div style="min-height: 150px;text-align: center;"><p style="color: #363640; font-weight: 700; text-align: center; padding: 8px 16px; font-size: 18px; margin: 0;">รายละเอียด</p><div style="color: #363640; text-align: center; padding-bottom: 16px"><p style="margin: 0; font-size: 16px">ปรับความคุ้มครองในแบบของคุณเช่น
          ค่ารักษาพยาบาลส่วนเกิน, ค่าทันตกรรม, แพทย์ทางเลือก</p></div></div>`,
          taggedPrice: 12000,
          defaultCoinValue: 12000,
          consentDetails: '',
          selected: false,
          isDefault: false,
        },
        {
          id: 3,
          choicesName: 'แผนที่ 3',
          choicesDescription: `<div style="min-height: 150px;text-align: center;"><p style="color: #363640; font-weight: 700; text-align: center; padding: 8px 16px; font-size: 18px; margin: 0;">รายละเอียด</p><div style="color: #363640; text-align: center; padding-bottom: 16px"><p style="margin: 0; font-size: 16px">ปรับความคุ้มครองในแบบของคุณเช่น
          ค่ารักษาพยาบาลส่วนเกิน, ค่าทันตกรรม, แพทย์ทางเลือก</p></div></div>`,
          taggedPrice: 12000,
          defaultCoinValue: 12000,
          consentDetails: '',
          selected: false,
          isDefault: false,
        },
        {
          id: 4,
          choicesName: 'แผนที่ 4',
          choicesDescription: `<div style="min-height: 150px;text-align: center;"><p style="color: #363640; font-weight: 700; text-align: center; padding: 8px 16px; font-size: 18px; margin: 0;">รายละเอียด</p><div style="color: #363640; text-align: center; padding-bottom: 16px"><p style="margin: 0; font-size: 16px">ปรับความคุ้มครองในแบบของคุณเช่น
          ค่ารักษาพยาบาลส่วนเกิน, ค่าทันตกรรม, แพทย์ทางเลือก</p></div></div>`,
          taggedPrice: 12000,
          defaultCoinValue: 12000,
          consentDetails: '',
          selected: false,
          isDefault: false,
        }
      ]
    }
  }, [])

  const benefitFamily: BenefitsCategoryWrapperList = useMemo(() => {
    return {
      benefitsCategoryId: 2,
      benefitsCategoryName: 'เลือกประเภท',
      benefitsCategoryCode: BenefitsCategoryCode.SPOUSE_CHILD,
      choices: [
        {
          id: 1,
          choicesName: 'แลก FLEXi Coin เพื่อรับบัตรประกันคู่สมรส-บุตร',
          choicesDescription: ``,
          taggedPrice: -8000,
          defaultCoinValue: -8000,
          consentDetails: '',
          selected: false,
          isDefault: false,
        },
        {
          id: 2,
          choicesName: 'ไม่แลกบัตรประกันคู่สมรส-บุตร',
          choicesDescription: ``,
          taggedPrice: 0,
          defaultCoinValue: 0,
          consentDetails: '',
          selected: false,
          isDefault: false,
        },
      ]
    }
  }, [])

  const onSubmit = useCallback((next: () => void) => {
    next()
  }, [])

  return (
    <>
      <ButtonInsurance
        background={color.WHITE}
        fontColor={color.CHOICES_PRIMARY}
        fontSize={16}
        onClick={onClickOpenPDF}
      >
        ดูรายละเอียดประกันทุกแผนเพิ่มเติม
      </ButtonInsurance>
      <GroupBenefits>
        <Header>
          <Text size={24} type="Bold" color={color.FONT_BLACK}>
            1. เลือกแผนประกันสุขภาพของคุณ (FLEXi Insurance)
          </Text>
        </Header>
        <SubGroupBenefitsField
          key={`${benefit.benefitsCategoryId}`}
          name={'ChoiceBenefits'}
          validate={[required]}
          benefit={data?.filter((item) => item.benefitsCategoryCode === BenefitsCategoryCode.SELF)[0] || benefit}
          numberGroupShow={isMobile ? 1 : 3}
        />
      </GroupBenefits>
      <GroupBenefits>
        <Header>
          <Text size={24} type="Bold" color={color.FONT_BLACK}>
            2. แลกบัตรประกันคู่สมรส-บุตร
          </Text>
        </Header>
        <SubGroupBenefitsField
          key={`${benefit.benefitsCategoryId}`}
          name={'ChoiceBenefitsFamly'}
          validate={filterRelativeData?.length === 0 ? [] : [required]}
          benefit={data?.filter((item) => item.benefitsCategoryCode === BenefitsCategoryCode.SPOUSE_CHILD)[0] || benefitFamily}
          numberGroupShow={isMobile ? 1 : 2}
          textButtonSelect={'เลือก'}
          disabled={filterRelativeData?.length === 0}
        />
        <FamilyList>
          {filterRelativeData?.map((item, index) => <FamilyCard key={index} relative={item} />)}
        </FamilyList>
        <ConditonLayout>
          <Text size={18} type='Bold'>เงื่อนไข</Text>
          <Text size={16}>1. คู่สมรสและบุตรโดยชอบด้วยกฎหมายของพนักงานซึ่งได้แจ้งไว้กับบริษัทฯ ตามผู้ใช้สิทธิบัตรประกันสุขภาพ</Text>
          <Text size={16}>2. กรณีพนักงานที่ไม่เคยลงทะเบียนชื่อคู่สมรส-บุตร หรือ ต้องการเพิ่มชื่อบุตรผู้เอาประกัน หรือ ปรับปรุงข้อมูลเดิมของผู้เอาประกัน สามารถลงทะเบียน หรือ แก้ไขได้ในข้อมูลประกันสุขภาพของพนักงานและคู่สมรส-บุตร</Text>
        </ConditonLayout>
      </GroupBenefits>
      <FooterContent onSubmit={onSubmit} isFamily={filterRelativeData?.length === 0 ? false : true} />
    </>
  )
}

export default SelectBenefit