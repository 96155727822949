import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import MaskImage from '../../assets/images/base/mask@2x.jpg'
import { CalendarIcon, IconClaim, IconFamilyInsurance, IconInsurance, IconNew, IconPlay } from '../../assets/images/images'
import CategoryGroup from '../../components/common/CategoryGroup'
import Image from '../../components/common/Image'
import Text from '../../components/common/Text'
import color from '../../constant/colors'
import { useGetEnrollmentActivePeriod, useGetEnrollmentSelectPeriod } from '../../services/enrollment/enrollment-query'
import { tablet } from '../../utils/responsive-helper'
import { useGetClaimTypeGroup } from '../../services/claim-type/claim-type.query'
import { Code } from '../../services/claim-type/claim-type.types'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.1);
  border: 0.5px solid #DDDDDD;
  border-radius: 8px;
  padding: 8px 16px;
  margin-bottom:16px;
  user-select: none;
  background-color: ${color.WHITE};
  :hover {
    cursor: pointer;
  }

  ${tablet} {
    width: 100%;
  }
`
const CustomImage = styled(Image)`
  width: 22px;
  height: 22px;
`
const AddAppIconLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 83px;
  height: 83px;
  border-radius: 20px;
  border: solid 2px ${color.ADD_APP_ICON};
`

type ItemProps = {
  src: string
  label: string
  url: string
  type?: string
  description: string | string[]
  hide?: boolean
  isExternalLink?: boolean
}

type MyAppProps = {
  isFlexi: boolean
}

const Item = (props: ItemProps) => {
  const { src, label, url, type, description, hide = false, isExternalLink = false, ...rest } = props
  const history = useHistory()
  const onClickExternalLink = useCallback(() => {
    window.open(url, '_blank')
  }, [url])
  const onClickInternalLink = useCallback(() => {
    history.push(url)
  }, [history, url])
  return (
    <>{!hide &&
      <Layout {...rest}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          rowGap: 8,
        }} onClick={isExternalLink ? onClickExternalLink : onClickInternalLink}>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            columnGap: 8,
          }}>
            <CustomImage src={src || MaskImage} />
            <Text size={14} type="Bold" style={{ textAlign: 'center', marginTop: 8, whiteSpace: 'nowrap' }}>
              {label}
            </Text>
          </div>
          {description instanceof Array ?
            description.map((item, index) => (
              <Text key={index} size={10}>{item}</Text>
            )) :
            <Text size={10}>{description}</Text>
          }

          {/* <Text size={14} style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            {labelEn}
          </Text> */}
        </div>
      </Layout>}
    </>
  )
}

const MyApp = (props: MyAppProps) => {
  const { data: activePeriod, isError: isErrorActivePeriod, isFetching: isFetchingActivePeriod } = useGetEnrollmentActivePeriod()
  const { isError: isErrorSelectPeriod, isFetching: isFetchingSelectPeriod } = useGetEnrollmentSelectPeriod()
  const { isFlexi } = props
  const { t, i18n } = useTranslation()
  const { data: claimTypeGroup } = useGetClaimTypeGroup()

  const isHealthInsurance = useMemo(() => {
    if (isFetchingActivePeriod || isFetchingSelectPeriod) return
    if (!isFlexi) return false
    if (!isErrorActivePeriod && !isErrorSelectPeriod) return false

    const isActivePeriod = dayjs(activePeriod?.startPeriodDate).isBefore(dayjs()) && dayjs(activePeriod?.endPeriodDate).isAfter(dayjs())

    return isErrorSelectPeriod && isActivePeriod

  }, [activePeriod, isErrorSelectPeriod, isFlexi, isErrorActivePeriod, isFetchingActivePeriod, isFetchingSelectPeriod])

  const path = useCallback((code: Code) => {
    switch (code) {
      case Code.OPD:
        return '/opd'
      case Code.FLEXI:
        return '/e_claim'
      default:
        return '/'
    }
  }, [])

  const icon = useCallback((code: Code) => {
    switch (code) {
      case Code.OPD:
        return IconInsurance
      case Code.FLEXI:
        return CalendarIcon
      default:
        return ''
    }
  }, [])

  return (
    <>
      <CategoryGroup label={t('home.category.myApp.title')} style={{ marginBottom: 16 }}>
        {claimTypeGroup?.map((item) => (
          <Item
            key={item.id}
            src={icon(item.code)}
            label={i18n.language === 'th' ? item.nameTh : item.nameEn}
            url={path(item.code)}
            type={'0'}
            hide={item.code === Code.FLEXI && !isFlexi}
            description={i18n.language === 'th' ? item.descriptionTh : item.descriptionEn}
          />
        ))}
        <Item
          src={IconClaim}
          label={t('home.category.myApp.insurance.title')}
          url={`/enrollment`}
          type={'0'}
          description={t('home.category.myApp.insurance.description')}
        />
        <Item
          src={IconFamilyInsurance}
          label={t('home.category.myApp.family.title')}
          url={`/relative`}
          type={'0'}
          description={t('home.category.myApp.family.description')}
        />
        {isHealthInsurance && (
          <Item
            src={IconInsurance}
            label={t('home.category.myApp.healthInsurance.title')}
            url={`/healthInsurance`}
            type={'0'}
            description={t('home.category.myApp.healthInsurance.description')}
          />
        )}
        {/* <Item
          key={1}
          src={IconCheckup}
          label={t('home.category.myApp.healthCheckup.title')}
          url={`/health-checkup`}
          type={'0'}
          description={t('home.category.myApp.healthCheckup.description')}
        /> */}
        {/* <Item
          key={1}
          src={IconHealth}
          label={t('home.category.myApp.wellBeing.title')}
          url={`/well-being`}
          type={'0'}
          description={t('home.category.myApp.wellBeing.description')}
        /> */}
        <Item
          src={IconInsurance}
          label={'BST FLEXi Information Site'}
          url={'https://bstazure.sharepoint.com/teams/BSTFLEXi'}
          isExternalLink={true}
          type={'0'}
          description="ข้อมูลเกี่ยวกับสวัสดิการ FLEXi และคู่มือการใช้ระบบ"
        />
        <Item
          src={CalendarIcon}
          label={'Marketplace'}
          url={'/shop'}
          type={'0'}
          description={["วงเงิน FLEXi Insurance + FLEXi Rewards", "*เฉพาะ coin คงเหลือที่ได้จากการเลือกรับบัตรประกันแผน Small*"]}
        // description=["วงเงิน FLEXi Insurance + FLEXi Rewards", "แลกรับสิทธิประโยชน์"]
        />
        <Item
          src={IconNew}
          label={t('home.category.myApp.news.title')}
          url={'/news'}
          type={'0'}
          description={t('home.category.myApp.news.description')}
        />
        <Item
          src={IconPlay}
          label={t('home.category.myApp.play.title')}
          url={`/play`}
          type={'0'}
          description={t('home.category.myApp.play.description')}
        />
        {/* <Item
          key={1}
          src={CalendarIcon}
          label={'Transfer Point'}
          url={'/shop'}
          type={'0'}
          description="แลกรับสิทธิประโยชน์"
        /> */}
      </CategoryGroup>
      {/* <Button background={color.WHITE} style={{ borderRadius: 8, width: '100%', maxWidth: 'none' }}>
        <Text size={14} type="SemiBold" style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>ปรับแต่งโมดูล</Text>
      </Button> */}
    </>
  )
}

export default MyApp
