import styled from '@emotion/styled'
import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form'
import {
  DatePickerField,
  InputField,
  SelectField,
  UploadfileField,
} from '../../components/common/fields'
import Card from '../../components/common/Card'
import Text from '../../components/common/Text'
import { mobile, mobileVerySmall } from '../../utils/responsive-helper'
import moment from 'moment'
import FooterContent from '../../components/FooterContent'
import Button from '../../components/common/Button'
import { EClaimFormParams, EClaimHistoryItem, EClaimItem } from '../../services/e-claim/e-claim-types'
import { ComponentType, useEffect, useMemo, useState } from 'react'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EClaimInf, EClaimInfoWithConcentDate, EClaimInfoWithoutConcentDate, setClaimInfo } from '../../redux-store/e-claim-reducer'
import { useHistory } from 'react-router'
import * as paths from '../../constant/path'
import Input, { InputLabel } from '../../components/common/Input'
import color from '../../constant/colors'
import { useGetUser, useGetUserCoin } from '../../services/user/user-query'
import {
  useCreateUserClaim,
  useGetRelativeOption,
  useUploadFile,
  // useUploadReceiptImage,
} from '../../services/e-claim/e-claim-query'
import { UserClaimDocumentList, FileType } from '../../services/e-claim/e-claim-types'
import { Moment } from 'moment'
import Modal from '../../components/common/Modal'
import { useVisibility } from '../../utils/custom-hook'
import { convertBase64ToFile, getToken, numberWithCommas, useRelativeTypeName } from '../../utils/helper'
import { Divider, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { CreateRelative } from '../Relative/component'
import { useTranslation } from 'react-i18next'
import OutlineButton from '../../components/common/OutlineButton'
import Image from '../../components/common/Image'
import config from '../../config'

const ClaimFormContainer = styled(Card)`
  padding: 16px;
  .layout-upload {
    ${mobile} {
      width: 100%;
    }
    ${mobileVerySmall} {
      width: 100%;
    }
  }
  ${mobile} {
    padding: 16px;
  }
  ${mobileVerySmall} {
    padding: 16px;
  }
`

const Spacer = styled.div`
  width: 20px;
  height: 20px;

  ${mobile} {
    display: none;
  }
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 16px;

  ${mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`

const FileUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;

  ${mobile} {
    flex-direction: column;
  }
`

const CustomEditableFooter = styled(FooterContent)`
  height: 80;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 18px;
  padding-bottom: 18px;

  ${mobile} {
    justify-content: center;
  }
`

const CustomUnEditableFooter = styled(FooterContent)`
  height: 80;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 18px;
  padding-bottom: 18px;
  column-gap: 16px;
  
  ${mobile} {
    flex-direction: column;
    justify-content: center;
  }
`

const SumPointContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;

  ${mobile} {
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
`

const BackToEditButton = styled(Button)`
  width: 267px;
`
const SubmitButton = styled(Button)`
  width: 267px;
`
const TextModal = styled(Text)`
  text-align: center;
`

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  /* border: 1px solid; */
  margin-bottom: 8px;
  padding-bottom: 0;

  ${mobile} {
    width: 100%;
  }
`

const RelativeField = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  column-gap: 8px;
`

const CreateRelativeButton = styled(Button)`
  width: fit-content;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ECLAIM_FORM_NAME = 'ECLAIM_FORM'
type EClaimItemType = EClaimItem & EClaimHistoryItem
type EClaimParam = {
  eClaimItem?: Partial<EClaimItemType>
  editable?: boolean
  eClaimFormValue?: EClaimInfoWithConcentDate & EClaimInfoWithoutConcentDate
  isPreview?: boolean
  allpayStatus?: boolean
  isHistory?: boolean
  eClaimId?: number
  isCreateNew?: 'true' | 'false'
}
const required = (value: string) => (value ? undefined : 'error.required')
const requiredDate = (value: Moment) => {
  const x = moment(value).isValid() ? undefined : 'error.required'
  return x
}
const EClaimForm: ComponentType<
  EClaimParam & InjectedFormProps<EClaimFormParams, EClaimParam, string>
> = (props) => {
  const dispatch = useDispatch()
  const {
    handleSubmit,
    invalid,
    eClaimItem,
    editable = true,
    eClaimFormValue,
    isPreview = false,
    allpayStatus,
    isHistory = false,
    eClaimId,
    initialize,
    isCreateNew
  } = props

  const formValuesSelector = getFormValues(ECLAIM_FORM_NAME)
  const formValues = useSelector<any, EClaimFormParams>(
    (state) => formValuesSelector(state) as EClaimFormParams,
  )
  const { t } = useTranslation()
  const [visible, modalAction] = useVisibility()
  const [visibleError, modalActionError] = useVisibility()
  const [errorMessage, setErrorMessage] = useState('')
  const [isClaimCheck, setIsClaimCheck] = useState(false)
  const [claimAmountCheck, setClaimAmountCheck] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const { data: user } = useGetUser()
  const { firstName, lastName, userId } = user || {}

  const fullname = `${firstName} ${lastName}`
  const history = useHistory()
  const { data: userCoin = 0 } = useGetUserCoin()
  const { data: relativesOptions, refetch: refetchRelative } = useGetRelativeOption(eClaimId)
  const { mutate: createUserClaim, isLoading: isSubmitting } = useCreateUserClaim()
  const { mutateAsync: uploadFile, isLoading: isUploading } = useUploadFile()
  // const { mutateAsync: uploadReceiptImage, isLoading: isUploadingReceipt, isError: isErrorUplaodReceiptImage } = useUploadReceiptImage()
  const relativeTypeName = useRelativeTypeName()

  useEffect(() => {
    if (isCreateNew) {
      initialize({
        amountReceipt: eClaimFormValue?.amountReceipt ? eClaimFormValue?.amountReceipt : undefined,
        amountClaim: eClaimFormValue?.amountClaim ? eClaimFormValue?.amountClaim : undefined,
        receiptFile: eClaimFormValue?.receiptFile ? eClaimFormValue?.receiptFile : '',
        optionalFileUrl: eClaimFormValue?.optionalFileUrl ? eClaimFormValue?.optionalFileUrl : '',
        relativeTypeId: eClaimFormValue?.relativeTypeId ? eClaimFormValue?.relativeTypeId : '0',
        receiptDate: eClaimFormValue?.receiptDate ? eClaimFormValue?.receiptDate : undefined,
      })
    } else {
      initialize({
        amountReceipt: formValues?.amountReceipt ? formValues?.amountReceipt : undefined,
        amountClaim: formValues?.amountClaim ? formValues?.amountClaim : undefined,
        receiptFile: formValues?.receiptFile ? formValues?.receiptFile : undefined,
        optionalFileUrl: formValues?.optionalFileUrl ? formValues?.optionalFileUrl : undefined,
        relativeTypeId: formValues?.relativeTypeId ? formValues?.relativeTypeId : '0',
        receiptDate: formValues?.receiptDate ? formValues?.receiptDate : undefined,
      })
    }
  }, [])

  // const amountClaimFormated = useMemo(() => {
  //   return eClaimFormValue?.amountClaim.toLocaleString(undefined, { maximumFractionDigits: 2 })
  // }, [eClaimFormValue?.amountClaim])

  const onSubmit = useCallback(
    (form: EClaimFormParams) => {

      dispatch(
        setClaimInfo({
          id: eClaimItem?.id,
          name: form.name,
          amountReceipt: form.amountReceipt,
          amountClaim: claimAmountCheck,
          receiptFile: form.receiptFile,
          optionalFileUrl: form.optionalFileUrl,
          relativeTypeId: form.relativeTypeId,
          receiptDate: form.receiptDate,
          claimName: form.claimName,
          consentDate: eClaimFormValue?.consentDate
        }),
      )
      if (editable === true) {
        history.push(paths.eClaimSummary({ routeParam: { eClaimId: eClaimItem?.id || 0 } }))
      }
    },
    [dispatch, eClaimItem?.id, editable, history, claimAmountCheck, eClaimFormValue?.consentDate],
  )

  const getImageUploadUrl = useCallback(async (image, documentTypeId) => {
    try {
      const imageFile = await convertBase64ToFile(image || '')
      const imageFileUrl = await uploadFile({ files: imageFile, documentTypeId })

      return imageFileUrl[0].name
    } catch (error) {
      console.log("error", error)

      return ''
    }
  }, [uploadFile])

  const onSubmitApplication = useCallback(async () => {
    setIsLoading(true)
    //uploadfile
    const optionalFileUrl = await getImageUploadUrl(eClaimFormValue?.optionalFileUrl || '', 9)
    const receiptImageURL = await getImageUploadUrl(eClaimFormValue?.receiptFile || '', 8)

    const userClaimDocumentList: UserClaimDocumentList[] = []
    if (receiptImageURL) {
      userClaimDocumentList.push({
        documentTypeId: 8,
        documentPath: receiptImageURL,
      })
    }
    if (optionalFileUrl) {
      userClaimDocumentList.push({
        documentTypeId: 9,
        documentPath: optionalFileUrl,
      })
    }
    createUserClaim(
      {
        claimId: eClaimItem?.id || 0,
        // claimName: fullname,
        // optionalFileUrl: optionalFileUrl || '',
        // receiptFile: eClaimFormValue?.receiptFile ? receiptImageURL : '',
        // userClaimableAmount: eClaimFormValue?.amountClaim!,
        receiptAmount: eClaimFormValue?.amountReceipt!,
        relativeId: eClaimFormValue?.relativeTypeId === '0' ? 0 : Number(eClaimFormValue?.relativeTypeId),
        receiptDate: dayjs(eClaimFormValue?.receiptDate).toISOString() || '',
        consentDate: eClaimFormValue?.consentDate || null,
        consentBy: userId || 0,
        userClaimDocumentList: userClaimDocumentList
      },
      {
        onSuccess: (props) => {
          setIsLoading(false)
          history.push(
            paths.eClaimCompleted({
              routeParam: { eClaimId: eClaimItem?.id || 0 },
              queryParam: {
                userClaimId: props?.id || 0,
              },
            }),
          )
        },
        onError: (error) => {
          setIsLoading(false)
          setErrorMessage(error.message)
          modalActionError.show()
        },
      },
    )
  }, [
    getImageUploadUrl,
    createUserClaim,
    eClaimItem?.id,
    eClaimFormValue?.amountReceipt,
    eClaimFormValue?.relativeTypeId,
    eClaimFormValue?.receiptDate,
    eClaimFormValue?.receiptFile,
    eClaimFormValue?.optionalFileUrl,
    eClaimFormValue?.consentDate,
    history,
    modalActionError,
    userId
  ])

  const onBackToEdit = useCallback(() => {
    history.push(paths.eClaimDetail({ routeParam: { eClaimId: eClaimItem?.id || 0 } }))
  }, [eClaimItem?.id, history])

  // const onCreateRelative = useCallback((relativeDetail: CreateRelativeParams) => {
  //   createRelative(relativeDetail, {
  //     onSuccess: () => {
  //       console.log('Create success')
  //       refetchRelative()
  //     },
  //     onError: (error: any) => {
  //       console.log('Create error')
  //       if (error.message === 'Reach maximum number of relative') {
  //         alert(`ข้อมูล ${relativeTypeName(relativeDetail.relativeTypeId)} เกินจำนวนที่กำหนด`)
  //       }
  //     }
  //   })
  // }, [createRelative, refetchRelative, relativeTypeName])
  const onCheckCoin = useCallback(() => {
    if (!formValues?.amountReceipt) return

    fetch(`${config.apiHost}/app/userClaim/claimableAmount?requestAmount=${formValues?.amountReceipt}`, {
      method: 'GET',
      headers: {
        'X-Auth-Token': `${getToken()}`
      }
    })
      .then((res) => res.json())
      .then((res) => {
        setClaimAmountCheck(res.claimableAmount)
        setIsClaimCheck(true)
        if (res.claimableAmount === 0 || formValues?.amountReceipt > res.claimableAmount) {
          modalAction.show()
        }
      })
  }, [modalAction, formValues])

  const validateImage = useCallback((name: 'receiptFile' | 'optionalFileUrl', isRequired) => {
    if (isPreview === false && (!formValues[name]) && isRequired) {
      return [required]
    } else {
      return undefined
    }
  }, [formValues, isPreview])

  const validateClaimAmount = useCallback(
    (value) => {
      if (eClaimItem?.claimPrice && value > eClaimItem?.claimPrice) {
        return 'eClaim.claimPriceError'
      }
    },
    [eClaimItem?.claimPrice],
  )

  const isEnoughCoin = useCallback(
    (value) => {
      if (value > userCoin) {
        return 'eClaim.coinError'
      }
    },
    [userCoin])

  const isNotZero = useCallback(
    (value) => {
      if (value === '0') {
        return 'error.required'
      }
    }
    , [])

  const nameRelative = useCallback(
    (relativeId: number | string) => {
      const data = relativesOptions?.map((relativesOption) => {
        const { value, label } = relativesOption
        if (relativeId.toString() === value) {
          return label
        }
      })
      return data?.length === 0 ? 'ตัวเอง' : data
    },

    [relativesOptions],
  )

  const fullNameSummary = useMemo(() => {
    if (!eClaimFormValue?.relativeTypeId) return fullname

    return eClaimFormValue?.relativeTypeId === '0' ? fullname : nameRelative(eClaimFormValue?.relativeTypeId)
  }, [eClaimFormValue?.relativeTypeId, fullname, nameRelative])

  const disbleField = allpayStatus ? true : false

  if (editable && !formValues) return null

  return (
    <>
      <Modal
        visible={visibleError}
        onCancel={() => {
          modalActionError.hide()
        }}
        onCancelText="ตกลง"
      >
        <div style={{ margin: '30px' }}>
          <TextModal size={24} type="Bold">
            {errorMessage}
          </TextModal>
          <TextModal>กรุณาตรวจสอบอีกครั้ง</TextModal>
        </div>
      </Modal>
      <Modal
        visible={visible}
        onCancel={() => {
          modalAction.hide()
        }}
        onCancelText="ตกลง"
      >
        <div style={{ margin: '30px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', rowGap: 8 }}>
          <Image imageName='iconAlertPrivacy' width={72} />
          <TextModal>FLEXi Item ของคุณเหลือไม่พอเบิก</TextModal>
          <TextModal>FLEXi Item ที่เหลือของคุณสามารถเบิกได้ {numberWithCommas(claimAmountCheck)} coins</TextModal>
        </div>
      </Modal>
      <ClaimFormContainer>
        <Text size={24} type="Bold">
          {editable ? 'กรอกรายละเอียดการเบิกสวัสดิการ' : 'ตรวจสอบรายละเอียด '}
        </Text>
        <InputContainer>
          {editable && (
            <FlexCol>
              <InputLabel size={18}>ชื่อ - นามสกุล ผู้ขอเบิก</InputLabel>
              <Text type="Bold" size={16}>{fullname}</Text>
            </FlexCol>
          )}
          {editable && (
            <FlexCol>
              <InputLabel size={18}>
                {t('eClaim.detail.form.claimFor')}{' '}
              </InputLabel>
              <RelativeField>
                <SelectField
                  name="relativeTypeId"
                  placeholder={t('eClaim.detail.form.claimFor')}
                  validate={[required]}
                  options={relativesOptions || []}
                  disabled={disbleField}
                  style={{ width: '100%' }}
                />
                {/* <CreateRelativeButton onClick={setVisibleCreate.show}>{t('family.addTitle')}</CreateRelativeButton> */}
              </RelativeField>
            </FlexCol>
          )}
          {isHistory && (
            <>
              <FlexCol>
                <InputLabel size={18}>{t('eClaim.detail.form.claimFor')}</InputLabel>
                <Text size={16} type='Bold'>{eClaimItem?.relativeId ? nameRelative(eClaimItem?.relativeId) : fullname}</Text>
              </FlexCol>
            </>
          )}
          {isPreview && (
            <FlexCol>
              <InputLabel size={18}>{t('eClaim.detail.form.claimFor')}</InputLabel>
              <Text size={16} type='Bold'>{fullNameSummary}</Text>
            </FlexCol>
          )}
        </InputContainer>
        <InputContainer>
          <FlexCol>
            {editable && (
              <DatePickerField
                name="receiptDate"
                label="วันที่ในใบเสร็จ"
                validate={[required, requiredDate]}
                disabled={disbleField}
                labelSize={18}
                maxDate={dayjs().endOf('day')}
                minDate={dayjs('07/01/2023')}

              />
            )}
            {isPreview && (
              <>
                <InputLabel size={18}>วันที่ในใบเสร็จ</InputLabel>
                <Text type="Bold">{dayjs(eClaimFormValue?.receiptDate).format('DD/MM/YYYY')}</Text>
              </>
            )}
          </FlexCol>
        </InputContainer>
        <InputContainer>
          <FlexCol>
            {editable && (
              <InputField
                name="amountReceipt"
                label={t('eClaim.detail.form.amountReceipt')}
                placeholder={t('eClaim.detail.form.inputAmount')}
                validate={[required, validateClaimAmount, isNotZero]}
                inputType="number"
                disabled={disbleField}
                labelSize={18}
              />
            )}
            {isPreview && (
              <>
                <InputLabel size={18}>{t('eClaim.detail.form.amountReceipt')}</InputLabel>
                <Text type="Bold" size={16}>
                  {eClaimFormValue?.amountReceipt ? numberWithCommas(Number(eClaimFormValue?.amountReceipt)) : 0}
                </Text>
              </>
            )}
            {isHistory && (
              <>
                <InputLabel size={18}>{t('eClaim.detail.form.amountReceipt')}</InputLabel>
                <Text type="Bold" size={16}>
                  {eClaimItem?.userClaimAmount ? numberWithCommas(Number(eClaimItem?.userClaimAmount)) : 0}
                </Text>
              </>
            )}
          </FlexCol>
          <FlexCol style={{ marginBottom: 0 }}>
            <InputLabel size={18}>{' '}</InputLabel>
            {editable && (<OutlineButton onClick={onCheckCoin}>ตรวจสอบสิทธิ</OutlineButton>)}
          </FlexCol>
          <FlexCol>
            {isClaimCheck && (
              <>
                <InputLabel size={18}>{t('eClaim.detail.form.claimAmount')}</InputLabel>
                <Text type="Bold" size={16}>{numberWithCommas(claimAmountCheck)}</Text>
              </>
            )}

            {/* {editable && (
              <InputField
                name="amountClaim"
                label={t('eClaim.detail.form.claimAmount')}
                placeholder={t('eClaim.detail.form.inputAmount')}
                // isEnoughCoin
                inputType="number"
                disabled={disbleField}
              />
            )} */}
            {isPreview && (
              <>
                <InputLabel size={18}>{t('eClaim.detail.form.claimAmount')}</InputLabel>
                <Text type="Bold" size={16}>
                  {eClaimFormValue?.amountClaim ? numberWithCommas(Number(eClaimFormValue?.amountClaim)) : 0}
                </Text>
              </>
            )}
            {isHistory && (
              <>
                <InputLabel size={18}>{t('eClaim.detail.form.claimAmount')}</InputLabel>
                <Text type="Bold" size={16}>
                  {eClaimItem?.userClaimAmountApprove ? numberWithCommas(Number(eClaimItem?.userClaimAmountApprove)) : 0}
                </Text>
              </>
            )}
          </FlexCol>
        </InputContainer>
        <Text size={24} type="Bold">
          {t('eClaim.detail.form.attachDocuments')}
        </Text>
        <FileUploadContainer>
          {!isHistory && eClaimItem && eClaimItem?.claimDocumentTypes?.map((item, index) => {
            return (
              <UploadfileField
                key={index}
                name={item.documentType.name === FileType.ReceiptFile ? 'receiptFile' : 'optionalFileUrl'}
                icon={'receiptIcon'}
                title={`${index + 1}. ${item.documentType.name}`}
                desc={item.documentType.description || ''}
                value={item.documentType.name === FileType.ReceiptFile ? eClaimFormValue?.receiptFile : eClaimFormValue?.optionalFileUrl}
                isDelete={isPreview}
                validate={validateImage(item.documentType.name === FileType.ReceiptFile ? 'receiptFile' : 'optionalFileUrl', item.isRequired)}
                maximumFileSize={3}
              // disabled={disbleField}
              />
            )
          })
          }
          {isHistory && eClaimItem && eClaimItem?.userClaimDocumentList?.map((item, index) => {
            return (
              <UploadfileField
                key={index}
                name={item.documentType.name === FileType.ReceiptFile ? 'receiptFile' : 'optionalFileUrl'}
                icon={'receiptIcon'}
                title={`${index + 1}. ${item.documentType.name}`}
                desc={item.documentType.description || ''}
                // value={eClaimFormValue?.userClaimDocumentList[index]?.documentPath}
                isDelete={isHistory}
                // validate={validateImage(item.documentType.name === FileType.ReceiptFile ? 'receiptFile' : 'optionalFileUrl', item.isRequired)}
                maximumFileSize={3}
                disabled={true}
              />
            )
          })
          }
          {/* {eClaimFormValue?.userClaimDocumentList?.map((item, index) => {
            return (
              <UploadfileField
                key={index}
                name={item.documentType.name === FileType.ReceiptFile ? 'receiptFile' : 'optionalFileUrl'}
                icon={'receiptIcon'}
                title={`${index + 1}. ${item.documentType.name}`}
                desc={t('app.upload.conditionUpload')}
                // value={isHistory ? item?.fileUrl : eClaimFormValue?.[item.documentType.name]}
                value={eClaimFormValue?.userClaimDocumentList[index]?.documentPath}
                isDelete={isPreview || isHistory}
                validate={validateImage}
                maximumFileSize={3}
                // disabled={disbleField}
              />
            )
          })} */}
          {/* {(eClaimFormValue?.receiptFile || isPreview === false) && (
            <UploadfileField
              name="receiptFile"
              icon={'receiptIcon'}
              title={`1. ${t('eClaim.detail.form.receipt')}`}
              desc={t('app.upload.conditionUpload')}
              // value={isHistory ? eClaimItem?.receiptFile : eClaimFormValue?.receiptFile}
              value={eClaimFormValue?.receiptFile}
              isDelete={isPreview || isHistory}
              validate={validateImage}
              maximumFileSize={3}
              disabled={disbleField}
            />
          )}
          {(eClaimFormValue?.optionalFileUrl || isPreview === false) && (
            <UploadfileField
              name="optionalFileUrl"
              icon={'receiptIcon'}
              title={`2. ${t('eClaim.detail.form.optionalFile')}`}
              desc={t('app.upload.conditionUpload')}
              // value={isHistory ? eClaimItem?.optionalFileUrl : eClaimFormValue?.optionalFileUrl}
              value={eClaimFormValue?.optionalFileUrl}
              isDelete={isPreview || isHistory}
              validate={validateImage}
              maximumFileSize={3}
              disabled={disbleField}
            />
          )} */}
        </FileUploadContainer>
        {editable && (
          <ButtonWrapper>
            <SubmitButton onClick={handleSubmit(onSubmit)} disabled={invalid || !isClaimCheck}>
              {t('eClaim.detail.form.next')}
            </SubmitButton>
          </ButtonWrapper>
        )}
        {isPreview && (
          <ButtonWrapper>
            <SubmitButton onClick={onSubmitApplication} disabled={isSubmitting || isUploading || isLoading}>
              {t('eClaim.detail.form.submit')}
            </SubmitButton>
          </ButtonWrapper>
        )}
      </ClaimFormContainer>
      {/* {editable && !isHistory && (
        <CustomEditableFooter>
          <SubmitButton onClick={handleSubmit(onSubmit)} disabled={invalid || disbleField}>
            {t('eClaim.detail.form.next')}
          </SubmitButton>
        </CustomEditableFooter>
      )} */}
      {/* {!editable && !isHistory && (
        <CustomUnEditableFooter>
          <SumPointContainer>
            <Text type="Bold">{t('eClaim.detail.form.totalAmount')}</Text>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Text type="Bold" color={color.INPUT_ERROR}>
                -{amountClaimFormated}
              </Text>
              &nbsp;
              <Text color={color.FONT_BLACK}>{t('app.currency')}</Text>&nbsp;
              <Text color={color.FONT_LIGHT_GRAY}>{`(${t('app.remain')} ${userRestCoinFormated} ${t('app.currency')})`}</Text>
            </div>
          </SumPointContainer>
          <BackToEditButton onClick={onBackToEdit} background={color.WHITE} fontColor={color.PRIMARY}>
            {t('eClaim.detail.form.editInfo')}
          </BackToEditButton>
          <SubmitButton onClick={onSubmitApplication} disabled={isSubmitting || isUploading}>
            {t('eClaim.detail.form.submit')}
          </SubmitButton>
        </CustomUnEditableFooter>
      )} */}
    </>
  )
}

export default reduxForm<EClaimFormParams, EClaimParam, string>({
  form: ECLAIM_FORM_NAME,
  // initialValues: {
  //   relativeTypeId: 'ตัวเอง',
  // },
})(EClaimForm)
