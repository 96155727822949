import Breadcrumb from '../../components/common/Breadcrumb'
import styled from '@emotion/styled'
import EClaimForm from './EClaimForm'
import { useRouter } from '../../utils/helper'
import { useGetEClaimById } from '../../services/e-claim/e-claim-query'
import EClaimInfo from './EClaimInfo'
import Loading from '../../components/common/Loading'
import { eClaim, eClaimDetail, enrollment, home } from '../../constant/path'
import { mobile, useScreen } from '../../utils/responsive-helper'
import { useMemo } from 'react'
import Headbar from '../../components/common/HeadBar'
import * as paths from '../../constant/path'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux-store'
import { EClaimInfoWithConcentDate, EClaimInfoWithoutConcentDate } from '../../redux-store/e-claim-reducer'

const BackgroundHeader = styled.div`
  background-color: #E7E2D8;
  width: calc(100% - 32px);
  height: 266px;
  margin-top: 0px !important;
  position: absolute;

  ${mobile} {
    width: 100%;
  }
`
const Layout = styled.div`
  width: 768px;
  margin: 0 auto;
  z-index: 1;
  > * {
    margin-top: 24px;
  }

  ${mobile} {
    width: 100%;
  }
`

const EClaimDetail = () => {
  const { query } = useRouter<{ eClaimId: number, claimTypeId: string, isCreateNew: 'true' | 'false' }>()

  const { isMobile } = useScreen()
  const { data: eClaimItem, isLoading } = useGetEClaimById({ claimId: query.eClaimId })
  const initialValue = useMemo(() => { return { relativeTypeId: 0, } }, [])
  const eClaimFormValue = useSelector((state: RootState) => state.eClaim.claimInfo) as EClaimInfoWithConcentDate & EClaimInfoWithoutConcentDate


  return (
    <Loading loading={false}>
      <BackgroundHeader />
      <Layout>
        <Headbar title={eClaimItem?.name || ''} backWard={paths.eClaim()} />
        {/* <EClaimInfo eClaimItem={eClaimItem} /> */}

        {/*   <EClaimDocument /> */}

        <EClaimForm eClaimItem={eClaimItem} eClaimFormValue={eClaimFormValue} allpayStatus={false} eClaimId={query.eClaimId} isCreateNew={query.isCreateNew} />
      </Layout>
    </Loading>
  )
}

export default EClaimDetail
