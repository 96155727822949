import { useQuery } from 'react-query'
import { api } from '../../utils/api'
import { ClaimTypeGroupListResponse } from './claim-type.types'

const APP = '/app'
export const CLAIM_TYPE_GROUP = `${APP}/claimTypeGroup`

export const useGetClaimTypeGroup = () => {
  return useQuery([CLAIM_TYPE_GROUP], () => {
    return api.belive.get<ClaimTypeGroupListResponse>(`${CLAIM_TYPE_GROUP}`)
  })
}