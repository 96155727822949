import React, { useCallback } from 'react'
import Loading from '../../components/common/Loading'
import { opdDetail } from '../../constant/path'
import { useScreen } from '../../utils/responsive-helper'
import { useRouter } from '../../utils/helper'
import { useGetEClaimHistoryById } from '../../services/e-claim/e-claim-query'
import { UserClaimDocumentList, FileType, ClaimDocumentTypes } from '../../services/e-claim/e-claim-types'
import OpdInfo from './OpdInfo'
import OpdForm from '../OPDDetail/OpdForm'
import { useMemo } from 'react'
import Headbar from '../../components/common/HeadBar'
import * as paths from '../../constant/path'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { setOpdInfo } from '../../redux-store/opd-reducer'
import config from '../../config'

export type InitialValueType = {
  name: string
  amountReceipt: number
  amountClaim: number
  receiptFile: string
  optionalFileUrl: string
  relativeTypeId: string
  receiptDate: string
  claimName: string
}

const OpdHistoryDetail = () => {
  const { query } = useRouter<{ id: number }>()
  const history = useHistory()
  const dispatch = useDispatch()
  const { data: opdItem, isLoading } = useGetEClaimHistoryById({ id: query.id })
  const {
    claim,
    userClaimDocumentList,
    userClaimAmount,
    userClaimAmountApprove,
    relativeId,
    slipDate,
  } = opdItem || {}

  const initialValue = useMemo(() => {
    return {
      name: claim?.name || '',
      amountReceipt: userClaimAmount,
      amountClaim: userClaimAmountApprove,
      receiptFile: `${config.apiHost}/files${userClaimDocumentList?.filter(item => item.documentType.name === FileType.ReceiptFile)[0]?.filePath || ''}`,
      optionalFileUrl: `${config.apiHost}/files${userClaimDocumentList?.filter(item => item.documentType.name === FileType.OptionalFileUrl)[0]?.filePath || ''}`,
      relativeTypeId: relativeId ? relativeId.toString() : '',
      receiptDate: slipDate,
      claimName: claim?.name || '',
    }
  }, [claim, userClaimAmount, userClaimAmountApprove, userClaimDocumentList, relativeId, slipDate])

  const disable = useMemo(() => {
    if (!opdItem?.approved && (opdItem?.approved !== null)) {
      return false
    } else {
      return true
    }
  }, [opdItem?.approved])

  const onCreateNewEClaim = useCallback(async () => {
    if (opdItem?.id === undefined) return

    let receiptFile: string = ''
    let optionalFileUrl: string = ''

    if (opdItem.userClaimDocumentList.filter(item => item.documentType.name === FileType.ReceiptFile).length > 0) {
      receiptFile = `${config.apiHost}/files${opdItem.userClaimDocumentList[0].filePath}`
    }
    if (opdItem.userClaimDocumentList.filter(item => item.documentType.name === FileType.OptionalFileUrl).length > 0) {
      optionalFileUrl = `${config.apiHost}/files${opdItem.userClaimDocumentList[1].filePath}`
    }

    dispatch(
      setOpdInfo({
        name: opdItem.claim.name || '',
        amountReceipt: opdItem.userClaimAmount,
        amountClaim: opdItem.userClaimAmountApprove,
        receiptFile,
        optionalFileUrl,
        relativeTypeId: opdItem.relativeId ? opdItem.relativeId.toString() : '',
        receiptDate: opdItem.slipDate,
        claimName: opdItem.claim.name || '',
        clinicType: opdItem.remark || '',
      }),
    )

    history.push(opdDetail({
      routeParam: {
        opdId: opdItem?.claimId
      },
      queryParam: {
        isCreateNew: 'true'
      }
    }))
  }, [opdItem, history, dispatch])

  if (!opdItem || isLoading) return null

  return (
    <Loading loading={isLoading}>
      <div style={{ paddingTop: '24px' }}>
        <Headbar title={claim?.name || ''} backWard={paths.opdHistory()} />
        <OpdInfo id={query.id} opdItem={opdItem} onCreateNewEClaim={onCreateNewEClaim} />
        <OpdForm
          opdItem={opdItem}
          opdId={opdItem.claimId}
          // eClaimItem={initialValue}
          // initialValues={eClaimItem?.claim}
          initialValues={initialValue}
          allpayStatus={disable}
          isHistory={true}
          editable={false}
        />
      </div>
    </Loading>
  )
}

export default OpdHistoryDetail