import { useSelector } from 'react-redux'
import OpdForm from './OpdForm'
import { RootState } from '../../redux-store'
import styled from '@emotion/styled'
import Breadcrumb from '../../components/common/Breadcrumb'
import EClaimInfo from '../EClaimDetail/EClaimInfo'
import { useRouter } from '../../utils/helper'
import { useGetEClaimById } from '../../services/e-claim/e-claim-query'
import { home } from '../../constant/path'
import { pullAt } from 'lodash'
import { useScreen } from '../../utils/responsive-helper'
import Headbar from '../../components/common/HeadBar'
import { opdDetail } from '../../constant/path'
import { useTranslation } from 'react-i18next'
import { mobile } from '../../utils/responsive-helper'
import { useGetOpdById } from '../../services/opd/opd-query'
import { OpdInfoWithConcentDate, OpdInfoWithoutConcentDate } from '../../redux-store/opd-reducer'

const BackgroundHeader = styled.div`
  background-color: #E7E2D8;
  width: calc(100% - 32px);
  height: 266px;
  margin-top: 0px !important;
  position: absolute;

  ${mobile} {
    width: 100%;
  }
`
const Layout = styled.div`
  width: 768px;
  margin: 0 auto;
  z-index: 1;
  > * {
    margin-top: 24px;
  }

  ${mobile} {
    width: 100%;
  }
`

const OpdSummary = () => {
  const { query } = useRouter<{ opdId: number }>()
  const { t } = useTranslation()
  const { isMobile } = useScreen()
  const { data: opdItem, isLoading } = useGetOpdById({ claimId: query.opdId })

  const OpdFormValue = useSelector((state: RootState) => state.opd.OpdInfo) as OpdInfoWithoutConcentDate & OpdInfoWithConcentDate

  return (
    <>
      <BackgroundHeader />
      <Layout>
        <Headbar title={opdItem?.name || ''} backWard={opdDetail({ routeParam: { opdId: query.opdId } })} />
        {/* <EClaimInfo eClaimItem={eClaimItem} editable={false} /> */}
        <OpdForm
          opdItem={opdItem}
          opdId={query.opdId}
          opdFormValue={OpdFormValue}
          editable={false}
          isPreview={true}
        />
      </Layout>
    </>
  )
}

export default OpdSummary
