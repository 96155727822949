import { createSlice } from '@reduxjs/toolkit'
import dayjs, { Dayjs } from 'dayjs'

export type OpdInfoWithConcentDate = {
  consentDate: Dayjs;
};

export type OpdInfoWithoutConcentDate = {
  id?: number;
  name: string;
  amountReceipt: number;
  amountClaim: number;
  receiptFile: string;
  optionalFileUrl: string;
  relativeTypeId: string;
  receiptDate: number;
  claimName: string;
  clinicType: string;
};

export type OpdInf = {
  OpdInfo: OpdInfoWithConcentDate | OpdInfoWithoutConcentDate;
}

export const initState: OpdInf = {
  OpdInfo: {
    name: '',
    amountReceipt: 0,
    amountClaim: 0,
    receiptFile: '',
    optionalFileUrl: '',
    relativeTypeId: '',
    receiptDate: 0,
    claimName: '',
    clinicType: ''
  },
}

const OpdSlice = createSlice({
  name: 'opd',
  initialState: initState,
  reducers: {
    setOpdInfo: (state, { payload }: PayloadWithType<OpdInf['OpdInfo']>) => {
      state.OpdInfo = payload
    },
    clearOpdInfo: (state) => {
      state.OpdInfo = initState.OpdInfo
    },
  },
})

export const opdReducer = OpdSlice.reducer

export const { setOpdInfo, clearOpdInfo } = OpdSlice.actions
