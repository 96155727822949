import { Link, useHistory } from 'react-router-dom'
import Text from '../../components/common/Text'
import { home, eClaim, eClaimHistory } from '../../constant/path'
// import { enrollmentDetail } from '../../constant/path'
import { useGetEClaimList } from '../../services/e-claim/e-claim-query'
import styled from '@emotion/styled'
import { mobile, useScreen, mobileVerySmall } from '../../utils/responsive-helper'
import * as paths from '../../constant/path'
import Card from '../../components/common/Card'
import OutlineButton from '../../components/common/OutlineButton'
import Image from '../../components/common/Image'
import dayjs from 'dayjs'
import Countdown from 'react-countdown'
import { useCallback, useMemo } from 'react'
import { PointCard } from '../../components/PointCard'
import { convertDateFormatTH, DateFormat } from '../../utils/dateFormat'
import color from '../../constant/colors'
import EClaimListComponent from './EClaimListComponent'
import Headbar from '../../components/common/HeadBar'
import Welfare from './Welfare'
import { useTranslation } from 'react-i18next'


const Layout = styled.div`
  width: 944px;
  margin: 0 auto;
  z-index: 1;
  > * {
    margin-top: 24px;
  }
  z-index: 1;
`
const TitleContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  margin-top: 32px;

  ${mobile} {
    align-items: center;
    flex-direction : column-reverse;
  }
`
const ButtonStyle = styled(OutlineButton)`
  width: fit-content;
  position: absolute;
  right: 0;
  cursor: pointer;
  margin: 0px 12px;

  ${mobile} {
    width: 100%;
    position: relative;
  }
`
const Container = styled(Card)`
  width: 768px;
  margin: 0 auto;
  padding: 40px;
`
const BackgroundHeader = styled.div`
  background-color: #E7E2D8;
  width: calc(100% - 32px);
  height: 266px;
  margin-top: 0px !important;
  position: absolute;

  ${mobile} {
    width: 100%;
  }
`

const EClaim = () => {
  const { isMobile } = useScreen()
  const history = useHistory()
  const { t } = useTranslation()

  const onClickEnrollment = useCallback(() => {
    // history.push(
    //   enrollmentDetail({
    //     routeParam: {
    //       benefitYearId: enrollmentPeriod?.benefitYearId!,
    //     },
    //   }),
    // )
  }, [history])

  return (
    <>
      <BackgroundHeader />
      <Layout style={{ paddingTop: '24px' }}>
        <Headbar title="FLEXi Item" backWard={paths.home()} />
        <Card>
          <Container>
            <PointCard isFlexi />
            <TitleContainer>
              <Welfare iconPath={'iconEclaimList'} title={t('eClaim.claimItemTitle')} descriptions={[]}></Welfare>
              <ButtonStyle onClick={() => history.push(eClaimHistory())}>
                {t('eClaim.historyClaimButton')}
              </ButtonStyle>
            </TitleContainer>
            <div style={{ height: '24px' }}></div>
            <EClaimListComponent />
          </Container>
        </Card>
      </Layout>
    </>
  )
}
export default EClaim
