import { useSelector } from 'react-redux'
import EClaimForm from '../EClaimDetail/EClaimForm'
import { RootState } from '../../redux-store'
import styled from '@emotion/styled'
import Breadcrumb from '../../components/common/Breadcrumb'
import EClaimInfo from '../EClaimDetail/EClaimInfo'
import { useRouter } from '../../utils/helper'
import { useGetEClaimById } from '../../services/e-claim/e-claim-query'
import { home } from '../../constant/path'
import { pullAt } from 'lodash'
import { useScreen } from '../../utils/responsive-helper'
import Headbar from '../../components/common/HeadBar'
import { eClaimDetail } from '../../constant/path'
import { useTranslation } from 'react-i18next'
import { mobile } from '../../utils/responsive-helper'
import { EClaimInfoWithConcentDate, EClaimInfoWithoutConcentDate } from '../../redux-store/e-claim-reducer'

const BackgroundHeader = styled.div`
  background-color: #E7E2D8;
  width: calc(100% - 32px);
  height: 266px;
  margin-top: 0px !important;
  position: absolute;

  ${mobile} {
    width: 100%;
  }
`
const Layout = styled.div`
  width: 768px;
  margin: 0 auto;
  z-index: 1;
  > * {
    margin-top: 24px;
  }

  ${mobile} {
    width: 100%;
  }
`

const EClaimSummary = () => {
  const { query } = useRouter<{ eClaimId: number }>()
  const { t } = useTranslation()
  const { isMobile } = useScreen()
  const { data: eClaimItem } = useGetEClaimById({ claimId: query.eClaimId })

  const eClaimFormValue = useSelector((state: RootState) => state.eClaim.claimInfo) as EClaimInfoWithConcentDate & EClaimInfoWithoutConcentDate

  return (
    <>
      <BackgroundHeader />
      <Layout>
        <Headbar title={eClaimItem?.name || ''} backWard={eClaimDetail({ routeParam: { eClaimId: query.eClaimId } })} />
        {/* <EClaimInfo eClaimItem={eClaimItem} editable={false} /> */}
        <EClaimForm
          eClaimItem={eClaimItem}
          eClaimId={query.eClaimId}
          eClaimFormValue={eClaimFormValue}
          editable={false}
          isPreview={true}
        />
      </Layout>
    </>
  )
}

export default EClaimSummary
