import {
  subHeaderEn,
  subHeaderTh,
  webAccessTextEn,
  webAccessTextTh,
  changeTermTextEn,
  changeTermTextTh,
  lawTextEn,
  lawTextTh,
} from './term-and-condition'
import { aboutTextEn, aboutTextTh } from './about-us'
import { enrollment, healthCheckup } from './modalPrivacy'

export const languages = [
  {
    label: 'ภาษาไทย',
    value: 'th',
  },
  {
    label: 'English',
    value: 'en',
  },
]

export const getTranslation = (key: 'en' | 'th') => {
  const text = (en: string, th: string): string => ({ en, th }[key])
  return {
    [key]: {
      translation: {
        app: {
          point: text('Point', 'คะแนน'),
          coin: text('Coin', 'เหรียญ'),
          baht: text('Baht', 'บาท'),
          sync: text('', 'ซิงค์ล่าสุด'),
          remain: text('REMAIN', 'คงเหลือ'),
          coinRemain: text('Coin remain', 'เหรียญคงเหลือ'),
          payAdditional: text('Pay Additional', 'มีค่าใช้จ่ายเพิ่มเติม'),
          continue: text('Continue', 'ดำเนินการต่อ'),
          photoUnit: text('photo', 'ภาพ'),
          ConvertedToMoney: text('Valued', 'คิดเป็นเงิน'),
          upload: {
            photo: text('Upload photo', 'อัพโหลดรูปภาพ'),
            gallery: text('Upload from Gallery', 'อัปโหลดจากคลังภาพ'),
            success: text('คุณได้ Upload รูปสำเร็จและได้รับเหรียญเรียบร้อยแล้ว', 'คุณได้ Upload รูปสำเร็จและได้รับเหรียญเรียบร้อยแล้ว'),
            successFullSlot: text('คุณได้ Upload รูปเรียบร้อยแล้ว', 'คุณได้ Upload รูปเรียบร้อยแล้ว'),
            conditionUpload: text('(jpg, png, pdf, zip ขนาดไม่เกิน 2 MB)', '(jpg, png, pdf, zip ขนาดไม่เกิน 2 MB)'),
          },
          navbar: {
            profile: text('Profile', 'Profile'),
            app: text('App', 'App'),
            termConditions: text('Terms and Conditions', 'Terms and Conditions'),
            about: text('About Belive', 'เกี่ยวกับ Belive'),
            language: text('Language', 'ภาษา'),
            version: text('Version', 'เวอร์ชัน'),
            logout: text('Log Out', 'ออกจากระบบ'),
          },
          breadcrumb: {
            insurance: text('Benefit adjustment', 'ปรับเปลี่ยนสวัสดิการ'),
            seeInsurance: text('ดูสวัสดิการ', 'ดูสวัสดิการ'),
            myInsurance: text('Your insurance plan', 'ประกันของคุณ'),
            myWelFare: text('Your benefits', 'สวัสดิการของคุณ'),
            changeInsurance: text('Change insurance plan', 'ปรับเปลี่ยนแผนประกัน'),
            myHealthCheckup: text('แผนตรวจสุขภาพของคุณ', 'แผนตรวจสุขภาพของคุณ'),
            changeHealthCheckup: text('Select Hospitals', 'เลือกโรงพยาบาล​')
          },
          coinNow: text('You have', 'ปัจจุบันคุณมีเหรียญ'),
          expiredOn: text('Expired on', 'หมดอายุในวันที่'),
        },
        profileCard: {
          availableCoin: text('Available coin', 'เหรียญที่มีอยู่'),
          titleJob: text('Position', 'ตำแหน่งงาน'),
          noJob: text('No job', 'ไม่มีอาชีพ'),
          coinHistory: text('Coins usage history', 'ประวัติการใช้เหรียญ'),
        },
        home: {
          subtitle: text('May today be a good day for you.', 'ขอให้วันนี้เป็นวันที่ดีของคุณ'),
          module: text('Module', 'โมดูล'),
          activity: text('Activity News', 'ข่าวสารกิจกรรม'),
          category: {
            myApp: {
              title: text('Menu', 'เมนู'),
              insurance: {
                title: text('Insurance', 'เลือกแผนสวัสดิการ'),
                description: text('Your Health Insurance Plan', 'เลือกแผนสวัสดิการ (Traditional / FLEXi)'),
              },
              eclaim: {
                title: text('E-Claims', 'E-Claims'),
                description: text('', 'เบิกค่ารักษาพยาบาล'),
              },
              marketplace: {
                title: text('Marketplace', 'ร้านค้า'),
                description: text('', 'แลกเหรียญสะสมกับร้านค้าที่ร่วมรายการ'),
              },
              play: {
                title: text('Play', 'Play'),
                description: text('Earn coins by joining activities.', 'สะสม Coin ผ่านการเข้าร่วมกิจกรรม'),
              },
              healthCheckup: {
                title: text('Health Checkup', 'Health Checkup'),
                description: text('Annual Health Check Up', 'ตรวจสุขภาพประจำปี'),
              },
              news: {
                title: text('News', 'News'),
                description: text('Company News & Updates', 'ข่าวสารภายในองค์กร'),
              },
              wellBeing: {
                title: text('Well-being', 'Well-being'),
                description: text('Take care of your health', 'ดูแลสุขภาพของคุณ'),
              },
              family: {
                title: text('ข้อมูลครอบครัว', 'ข้อมูลครอบครัว'),
                description: text('รายชื่อครอบครัวที่สามารถเบิก FLEXi item ได้ (นอกแผนประกันคู่สมรสและบุตร)', 'รายชื่อครอบครัวที่สามารถเบิก FLEXi item ได้ (นอกแผนประกันคู่สมรสและบุตร)'),
              },
              healthInsurance: {
                title: text('ประกันสุขภาพของพนักงาน และ คู่สมรส-บุตร', 'ประกันสุขภาพของพนักงาน และ คู่สมรส-บุตร'),
                description: text('แผนประกันสุขภาพพนักงาน คู่สมรสและบุตร', 'แผนประกันสุขภาพพนักงาน คู่สมรสและบุตร'),
              }
            },
          },
        },
        play: {
          title: text('Hi', 'สวัสดี'),
          subtitle: text('Activities that support you to live the way you like.', 'กิจกรรมที่สนับสนุนให้คุณได้ใช้ชีวิตในแบบที่ชอบ'),
          events: text('Events', 'กิจกรรม'),
          myevent: text('My Events', 'กิจกรรมที่เข้าร่วมแล้ว'),
          rewardRemain: text('Reward remain', 'รางวัลคงเหลือ'),
          cardList: {
            button: text('Activity details', 'รายละเอียดกิจกรรม')
          },
          upload: {
            gallery: text('Upload photo for activity', 'Upload ภาพเพื่อเข้าร่วมกิจกรรม'),
          },
          eventNotStart: text('Event not start', 'ขออภัย กิจกรรมยังไม่เปิดให้เข้าร่วม'),
          eventEnd: text('Event end', 'ขออภัย กิจกรรมสิ้นสุดแล้ว'),
          joinEvent: text('Join Event', 'เข้าร่วมกิจกรรม'),
          eventFull: text('Sorry, complementary coin is fully redeemed.', 'ขออภัย จำนวนผู้เข้าร่วมกิจกรรมและได้เหรียญครบกำหนดแล้ว'),
          eventDetail: text('Activity details', 'รายละเอียดกิจกรรม'),
          modalConfirmJoin: {
            title: text('ยืนยันการเข้าร่วมกิจกรรม', 'ยืนยันการเข้าร่วมกิจกรรม'),
            subtitle: text('การเข้าร่วมกิจกรรมจะต้องมีการ Upload รูปร่วมด้วย คุณยืนยันที่จะเข้าร่วมหรือไม่', 'การเข้าร่วมกิจกรรมจะต้องมีการ Upload รูปร่วมด้วย คุณยืนยันที่จะเข้าร่วมหรือไม่'),
            submit: text('Submit', 'ยืนยัน'),
            cancel: text('Cancel', 'ยกเลิก'),
            success: text('คุณได้เข้าร่วมกิจกรรมเรียบร้อยแล้ว กรุณา upload รูป', 'คุณได้เข้าร่วมกิจกรรมเรียบร้อยแล้ว กรุณา upload รูป'),
          }
        },
        footer: {
          link: {
            contactUs: text('Contact us', 'ติดต่อเรา'),
            privacyAndTerms: text('Privacy and terms', 'ข้อกำหนดและเงื่อนไข'),
          },
        },
        login: {
          input: {
            username: {
              label: text('Email', 'อีเมล'),
              placeholder: text('Please enter email address', 'ระบุอีเมล'),
            },
            password: {
              label: text('Password', 'รหัสผ่าน'),
              placeholder: text('Please enter password', 'ระบุรหัสผ่าน'),
            },
          },
          button: {
            submit: text('Login', 'เข้าสู่ระบบ'),
            submitDentsu: text('Login with dentsu account', 'เข้าสู่ระบบ ด้วย dentsu account'),
          },
          content: {
            flexBenefits: text('Flex Benefits', 'Flex Benefits'),
            content1: text(
              'ปรับเปลี่ยนสวัสดิการได้อย่างอิสระ',
              'ปรับเปลี่ยนสวัสดิการได้อย่างอิสระ',
            ),
            content2: text('สามารถทำได้เหมือนกับ Application', 'สามารถทำได้เหมือนกับ Application'),
            content3: text('เข้าสู่ระบบเลย!', 'เข้าสู่ระบบเลย!'),
          },
          formError: {
            username: text('Please input username', 'ระบุชื่อผู้ใช้'),
            password: text('Please input password', 'ระบุรหัสผ่าน'),
          },
        },
        news: {
          header: {
            news: text("News", "ข่าวสาร"),
            event: text("Event", "กิจกรรม"),
          },
          recommend: text('Recommend', 'แนะนำ'),
          more: text('Load More', 'เพิ่มเติม'),
        },
        newsDetail: {
          readButton: text('Accept', 'อ่านแล้ว'),
          modalTextCoin: text('คุณได้รับเหรียญจากข่าวนี้เรียบร้อยแล้ว', 'คุณได้รับเหรียญจากข่าวนี้เรียบร้อยแล้ว',),
          modalTextCoinUnCorrect: text('เราได้รวบรวมคำตอบของคุณไว้แล้ว', 'เราได้รวบรวมคำตอบของคุณไว้แล้ว',),
          modalTextNoCoin: text('คุณได้อ่านข่าวแล้ว', 'คุณได้อ่านข่าวแล้ว'),
          modalTextFail: text('กรุณาเลือกคำตอบให้ครบทุกข้อ ก่อนส่งคำตอบ', 'กรุณาเลือกคำตอบให้ครบทุกข้อ ก่อนส่งคำตอบ'),
          modalButtonText: text('Confirm', 'ตกลง'),
          sentAnswer: text('ส่งคำตอบ', 'ส่งคำตอบ'),
          vote: text('Vote', 'โหวต'),
          register: text('Register', 'ลงทะเบียน'),
          join: text('Join', 'เข้าร่วม'),
        },
        error: {
          required: text('Required', 'จำเป็นต้องกรอก'),
          numberic: text('Numberic', 'ข้อมูลต้องเป็นตัวเลขเท่านั้น'),
          moreMaxValue: text('More max value', 'จำนวนเหรียญมากกว่าที่สามารถจะใช้ได้'),
          zero: text('Must more 0', 'จำนวนเหรียญต้องมากว่า 0 '),
        },
        setting: {
          header: text('Personal Information', 'ข้อมูลส่วนตัว'),
          contactHeader: text('Contact Information', 'ข้อมูลการติดต่อ'),
          email: text('Email', 'อีเมล'),
          address: text('Address', 'ที่อยู่'),
          phoneNumber: text('Tel', 'เบอร์โทรศัพท์'),
          editButton: text('Edit Profile', 'แก้ไขข้อมูลการติดต่อ'),
          editRelative: text('Add/Edit Family List', 'เพิ่ม/แก้ไข รายชื่อครอบครัว'),
          familyWelfareHeader: text('Family Information', 'ข้อมูลครอบครัวที่มีสิทธิ์ใช้สวัสดิการ'),
          familyWelfareNote: text(
            'หากต้องการเพิ่ม / แก้ไขรายชื่อคนในครอบครัว กรุณาติดต่อกับ HR ของบริษัท',
            'หากต้องการเพิ่ม / แก้ไขรายชื่อคนในครอบครัว กรุณาติดต่อกับ HR ของบริษัท',
          ),
          citizenId: text('Citizen Id', 'บัตรประชาชน'),
          age: text('Age', 'อายุ'),
          year: text('year', 'ปี'),
          editContactHeader: text('Edit Contact Information', 'แก้ไขข้อมูลการติดต่อ'),
          contactAddress: {
            country: text('country', 'ประเทศ'),
            province: text('province', 'จังหวัด'),
            district: text('district', 'เขต/อำเภอ'),
            subdistrict: text('subdistrict', 'แขวง/ตำบล'),
            postcode: text('postcode', 'ไปรษณีย์'),
          },
          confirmbutton: text('Confirm', 'ยืนยัน'),
          submitButton: text('Save', 'บันทึก'),
          changeLang: text('Change Language', 'เปลี่ยนภาษา'),
          version: text('Version', 'เวอร์ชั่น'),
        },
        shop: {
          redeem: text('Redeem', 'ใช้เหรียญเพื่อแลก'),
          expired: text('Expired', 'หมดเขตวันที่'),
          seeMore: text('See More', 'เพิ่มเติม'),
        },
        termAndCondition: {
          header: text('Terms and Conditions', 'ข้อกำหนดและเงื่อนไข'),
          subHeader: text(subHeaderEn, subHeaderTh),
          webAccessHeader: text('1. Access to Our Website', '1. การเข้าใช้งานเว็บไซต์ของเรา'),
          webAccessText: text(webAccessTextEn, webAccessTextTh),
          changeTermHeader: text('2. Change to Terms', '2. การเปลี่ยนแปลงข้อกำหนด'),
          changeTermText: text(changeTermTextEn, changeTermTextTh),
          lawHeader: text('3. Governing Laws', '3. กฎหมายที่ใช้บังคับ'),
          lawText: text(lawTextEn, lawTextTh),
        },
        about: {
          header: text('About Belive', 'เกี่ยวกับ Belive'),
          content_1B: text(`BE`, `BE`),
          content_1: text(
            ` The Power to choose and take action by self`,
            ` The Power to choose and take action by self`,
          ),
          content_2B: text(`LIVE `, `LIVE `),
          content_2: text(` Our promise to people`, ` Our promise to people`),
          content_3B: text(`BENEFIT OF LIVING`, `BENEFIT OF LIVING`),
          content_3: text(
            ` Believe in the pursuit of lifestyle for life fulfillment`,
            ` Believe in the pursuit of lifestyle for life fulfillment`,
          ),
          content_4: text(aboutTextEn, aboutTextTh),
        },
        contact: {
          header: text('Contact Us', 'ติดต่อเรา'),
          phoneLabel: text('Tel', 'เบอร์โทรศัพท์'),
          emailLabel: text('Email', 'อีเมล'),
          addressLabel: text('Address', 'ที่อยู่'),
        },
        comment: {
          header: text('COMMENT', 'COMMENT'),
          placeholder: text('Comment...', 'ความคิดเห็น...'),
          placeholderFull: text('Your Comment ...', 'แสดงความคิดเห็น...'),
          submitButton: text('Send', 'แสดงความคิดเห็น'),
          commentCount: text('Comments', 'ความคิดเห็น'),
          commentEmpty: text('No Comment', 'ไม่มีการแสดงความคิดเห็น'),
          loading: text('Loading Comment...', 'กำลังโหลดคอมเมนต์...'),
        },
        appShortcut: {
          pinToHome: text('เพิ่มไปยังหน้าแรก', 'เพิ่มไปยังหน้าแรก'),
          pinToHomeShort: text('หน้าแรก', 'หน้าแรก'),
          unPinToHome: text('เพิ่มไปยังหน้าแรกแล้ว', 'เพิ่มไปยังหน้าแรกแล้ว'),
          unPinToHomeShort: text('เพิ่มแล้ว', 'เพิ่มแล้ว'),
          pinFaildShort: text('ไม่สามารถเพิ่มได้', 'ไม่สามารถเพิ่มได้'),
          pinShortCut: text('App Shortcut', 'App Shortcut'),
          confirm: text('ยืนยัน', 'ยืนยัน'),
          ok: text('ตกลง', 'ตกลง'),
          pinModal: {
            title: text('เพิ่มไปยังหน้าแรก', 'เพิ่มไปยังหน้าแรก'),
            messageStart: text('ต้องการเพิ่ม App ', 'ต้องการเพิ่ม App '),
            messageEnd: text(' ไปยังหน้าแรก', ' ไปยังหน้าแรก'),
          },
          unPinModal: {
            title: text('นำออกจากหน้าแรก', 'นำออกจากหน้าแรก'),
            messageStart: text('ต้องการนำ App ', 'ต้องการนำ App '),
            messageEnd: text(' ออกจากหน้าแรก', ' ออกจากหน้าแรก'),
          },
        },
        shopRewardClaim: {
          header: text('ยืนยันการหักเหรียญ / ชำระส่วนต่าง', 'ยืนยันการหักเหรียญ / ชำระส่วนต่าง'),
          successHeader1: text('คุณได้ทำรายการ', 'คุณได้ทำรายการ'),
          successHeader2: text('หักเหรียญสำเร็จแล้ว', 'หักเหรียญสำเร็จแล้ว'),
          currentUserPoint: text('เหรียญที่มีอยู่', 'เหรียญที่มีอยู่'),
          pointOfProduct: text('หักเหรียญที่มีอยู่', 'หักเหรียญที่มีอยู่'),
          pointLeft: text('เหรียญคงเหลือ', 'เหรียญคงเหลือ'),
          claimButtonText: text('Redeem with Belive Coin', 'ใช้เหรียญเพื่อแลก'),
          confirm: text('Confirm', 'ตกลง'),
        },
        pointHistory: {
          seeMore: text('See More', 'เพิ่มเติม'),
          title: text('Transaction History', 'ประวัติการใช้เหรียญ'),
          coin: text('เหรียญ', 'เหรียญ'),
          dealDate: text('แลกเมื่อ', 'แลกเมื่อ'),
        },
        notification: {
          seeMore: text('See More', 'เพิ่มเติม'),
          title: text('Notification', 'การแจ้งเตือน'),
        },
        enrollment: {
          insurance: text('Insurance Plan', 'แผนประกัน'),
          myInsurance: text('Insurance', 'ประกันของตัวเอง'),
          titleInsurance: text('You can adjust your insurance plan that suit your lifestyle from', 'ท่านสามารถปรับเปลี่ยนแผนประกันที่เหมาะสมกับไลฟ์สไตล์ของคุณได้ตั้งแต่วันที่'),
          coinOFYear: text('Annual coin', 'เหรียญประจำปี'),
          insuranceList: text('List of Adjustable Benefits', 'สวัสดิการบริษัทที่สามารถปรับเปลี่ยนได้'),
          nextButton: text('Next', 'ถัดไป'),
          selfBenefits: {
            title: text('Your insurance plan', 'ประกันของคุณ'),
            insuranceSelected: text('Your selected insurance plan', 'แผนประกันที่คุณเลือก'),
            selected: text('ที่คุณเลือก', 'ที่คุณเลือก'),
            changeInsurance: text('Change your insurance plan', 'เปลี่ยนแผนใหม่'),
            addInsurance: text('Choose your own insurance plan', 'เลือกแผนประกันตนเอง'),
            modalInsuranceDetail: {
              detail: text(`พนักงานสามารถตรวจสอบรายละเอียดกรรมธรรม์ของแผนประกันสำหรับตนเองได้ตามไฟล์ PDF ด้านล่าง`,
                `พนักงานสามารถตรวจสอบรายละเอียดกรรมธรรม์ของแผนประกันสำหรับตนเองได้ตามไฟล์ PDF ด้านล่าง`),
              title: text('แผนประกันสุขภาพและอุบัติเหตุประจำปี', 'แผนประกันสุขภาพและอุบัติเหตุประจำปี'),
              notShowAgain: text('ไม่ต้องแสดงอีก', 'ไม่ต้องแสดงอีก'),
            },
          },
          editSelfBenefits: {
            header: text('Select your insurance plan', 'เลือกแผนประกันของตนเอง'),
            select: text('Select insurance plan', 'เลือกแผนประกัน'),
            notification: {
              error: {
                noChange: text('ไม่สามารถบันทึกข้อมูลได้ เนื่องจากไม่ได้ทำการเลือกแผนประกัน', 'ไม่สามารถบันทึกข้อมูลได้ เนื่องจากไม่ได้ทำการเลือกแผนประกัน'),
                detail: text('ไม่สามารถบันทึกข้อมูลได้', 'ไม่สามารถบันทึกข้อมูลได้'),
              }
            },
            summary: {
              title: text('Summary of coverage options', 'สรุปการเลือกความคุ้มครอง'),
              totalCoin: text('total used coins', 'รวมใช้เหรียญ'),
            }
          },
          modalAcceptPrivacy: {
            header: text('Letter of Consent', 'หนังสือยินยอม'),
            accept: text('I confirm that I select this insurance plan with my consideration.', 'คุณยืนยันเลือกแบบแผนประกันนี้ด้วยตนเอง'),
            confirm: text('Confirm', 'ตกลง'),
            modalOne: {
              title: text(enrollment.modalPrivacyOne.en.title, enrollment.modalPrivacyOne.th.title),
              one: text(enrollment.modalPrivacyOne.en.one, enrollment.modalPrivacyOne.th.one),
              two: text(enrollment.modalPrivacyOne.en.two, enrollment.modalPrivacyOne.th.two),
              three: text(enrollment.modalPrivacyOne.en.three, enrollment.modalPrivacyOne.th.three),
              four: text(enrollment.modalPrivacyOne.en.four, enrollment.modalPrivacyOne.th.four),
              five: text(enrollment.modalPrivacyOne.en.five, enrollment.modalPrivacyOne.th.five),
              six: text(enrollment.modalPrivacyOne.en.six, enrollment.modalPrivacyOne.th.six),
              seven: text(enrollment.modalPrivacyOne.en.seven, enrollment.modalPrivacyOne.th.seven),
              eight: text(enrollment.modalPrivacyOne.en.eight, enrollment.modalPrivacyOne.th.eight),
              footer: text(enrollment.modalPrivacyOne.en.footer, enrollment.modalPrivacyOne.th.footer),
            },
            modalTwo: {
              content: text(enrollment.modalPrivacyTwo.en, enrollment.modalPrivacyTwo.th),
            }
          },
          condition: {
            header: text('เงื่อนไขและข้อกำหนด', 'เงื่อนไขและข้อกำหนด'),
            list1: text('พนักงานทีมีสิทธิ์เลือกแผนประกัน คือ พนักงานที่ผ่านทดลองก่อนวันที่', 'พนักงานทีมีสิทธิ์เลือกแผนประกัน คือ พนักงานที่ผ่านทดลองก่อนวันที่'),
            list2: text('กรณีพนักงานที่ผ่านทดลองงานหลังวันที่ 1 มกราคม 2566 ทางบริษัทฯ จะเลือกแผนประกันตามสิทธิ์ (default) ให้โดยอัตโนมัติเมื่อท่านผ่านทดลองงาน', 'กรณีพนักงานที่ผ่านทดลองงานหลังวันที่ 1 มกราคม 2566 ทางบริษัทฯ จะเลือกแผนประกันตามสิทธิ์ (default) ให้โดยอัตโนมัติเมื่อท่านผ่านทดลองงาน'),
            list3: text('หากท่านเลือกปรับแผนความคุ้มครองสูงขึ้น ค่าใช้จ่ายส่วนต่างจะถูกเรียกเก็บจากท่านผ่าน payroll', 'หากท่านเลือกปรับแผนความคุ้มครองสูงขึ้น ค่าใช้จ่ายส่วนต่างจะถูกเรียกเก็บจากท่านผ่าน payroll'),
          }
        },
        healthCheckup: {
          healthCheckup: text('Hospital', 'โรงพยาบาล'),
          myHealthCheckup: text('Health Checkup Hospitals', 'โรงพยาบาลของตนเอง'),
          titleHealthCheckup: text('You can adjust your health checkup hospital that suit your lifestyle from', 'ท่านสามารถเลือกโรงพยาบาลตรวจสุขภาพที่เหมาะสมกับไลฟ์สไตล์ของคุณได้ตั้งแต่วันที่'),
          coinOFYear: text('Annual coin', 'เหรียญประจำปี'),
          insuranceList: text('List of available flexible benefit', 'สวัสดิการบริษัทที่สามารถปรับเปลี่ยนได้'),
          nextButton: text('Next', 'ถัดไป'),
          selfBenefits: {
            title: text('My Hospital', 'โรงพยาบาลของคุณ'),
            healthCheckupSelected: text('Selected Hospitals', 'โรงพยาบาลที่คุณเลือก'),
            selected: text('ที่คุณเลือก', 'ที่คุณเลือก'),
            changeHealthCheckup: text('Change health checkup hospitals', 'เลือกโรงพยาบาล'),
            addHealthCheckup: text('Select your health checkup hospitals', 'เลือกโรงพยาบาลตนเอง'),
            modalInsuranceDetail: {
              detail: text(`พนักงานสามารถตรวจสอบข้อมูลรายการตรวจสุขภาพได้โดยคลิกที่ PDF ด้านล่าง`,
                `พนักงานสามารถตรวจสอบข้อมูลรายการตรวจสุขภาพได้โดยคลิกที่ PDF ด้านล่าง`),
              title: text('แผนประกันสุขภาพและอุบัติเหตุประจำปี', 'แผนประกันสุขภาพและอุบัติเหตุประจำปี'),
              notShowAgain: text('ไม่ต้องแสดงอีก', 'ไม่ต้องแสดงอีก'),
            },
          },
          editSelfBenefits: {
            header: text('Select health checkup hospital', 'เลือกโรงพยาบาลตรวจสุขภาพ'),
            select: text('Select Hospital', 'เลือกโรงพยาบาล'),
            notification: {
              error: {
                noChange: text('ไม่สามารถบันทึกข้อมูลได้ เนื่องจากไม่ได้ทำการเลือกโรงพยาบาล', 'ไม่สามารถบันทึกข้อมูลได้ เนื่องจากไม่ได้ทำการเลือกโรงพยาบาล'),
                detail: text('ไม่สามารถบันทึกข้อมูลได้', 'ไม่สามารถบันทึกข้อมูลได้'),
              }
            },
            summary: {
              title: text('Summary of hospital selection', 'สรุปการเลือกโรงพยาบาล'),
              totalCoin: text('total used coins', 'รวมใช้เหรียญ'),
            }
          },
          modalAcceptPrivacy: {
            header: text('Consent letter', 'หนังสือยินยอม'),
            accept: text('You confirm selecting this hospital for your health checkup.', 'คุณยืนยันเลือกโรงพยาบาลนี้สำหรับตรวจสุขภาพ'),
            confirm: text('Confirm', 'ตกลง'),
            modalOne: {
              title: text(healthCheckup.modalPrivacyOne.en.title, healthCheckup.modalPrivacyOne.th.title),
              one: text(healthCheckup.modalPrivacyOne.en.one, healthCheckup.modalPrivacyOne.th.one),
              two: text(healthCheckup.modalPrivacyOne.en.two, healthCheckup.modalPrivacyOne.th.two),
              three: text(healthCheckup.modalPrivacyOne.en.three, healthCheckup.modalPrivacyOne.th.three),
              four: text(healthCheckup.modalPrivacyOne.en.four, healthCheckup.modalPrivacyOne.th.four),
              five: text(healthCheckup.modalPrivacyOne.en.five, healthCheckup.modalPrivacyOne.th.five),
              six: text(healthCheckup.modalPrivacyOne.en.six, healthCheckup.modalPrivacyOne.th.six),
              seven: text(healthCheckup.modalPrivacyOne.en.seven, healthCheckup.modalPrivacyOne.th.seven),
              footer: text(healthCheckup.modalPrivacyOne.en.footer, healthCheckup.modalPrivacyOne.th.footer),
            },
          },
          condition: {
            header: text('เงื่อนไขและข้อกำหนด', 'เงื่อนไขและข้อกำหนด'),
            list1: text('พนักงานที่มีสิทธิ์ตรวจสุขภาพประจำปี 2566 คือ พนักงานที่เข้างานก่อนวันที่ 1 มีนาคม 2566 และผ่านทดลองงานแล้วเท่านั้น', 'พนักงานที่มีสิทธิ์ตรวจสุขภาพประจำปี 2566 คือ พนักงานที่เข้างานก่อนวันที่ 1 มีนาคม 2566 และผ่านทดลองงานแล้วเท่านั้น'),
            list2: text('กรณีพนักงานที่ผ่านทดลองงานหลังวันที่ 1 มกราคม 2566 ทางบริษัทฯ จะเลือกโรงพยาบาลสมิติเวชให้โดยอัตโนมัติ', 'กรณีพนักงานที่ผ่านทดลองงานหลังวันที่ 1 มกราคม 2566 ทางบริษัทฯ จะเลือกโรงพยาบาลสมิติเวชให้โดยอัตโนมัติ'),
            list3: text('หากท่านเลือกโรงพยาบาลที่มีค่าใช้จ่ายน้อยกว่าโรงพยาบาลสมิติเวช ท่านจะได้รับเหรียญส่วนต่างเพิ่ม', 'หากท่านเลือกโรงพยาบาลที่มีค่าใช้จ่ายน้อยกว่าโรงพยาบาลสมิติเวช ท่านจะได้รับเหรียญส่วนต่างเพิ่ม'),
            list4: text('ทางบริษัทฯ ได้ติดต่อขอราคาพิเศษในการตรวจสุขภาพเพิ่มเติมสำหรับพนักงานและครอบครัวไว้แล้ว โดยสามารถไปแจ้งว่าเป็นครอบครัวของพนักงาน Dentsu กับทางโรงพยาบาลได้เลย แต่พนักงานต้องรับผิดชอบค่าใช้จ่ายส่วนนี้เอง', 'ทางบริษัทฯ ได้ติดต่อขอราคาพิเศษในการตรวจสุขภาพเพิ่มเติมสำหรับพนักงานและครอบครัวไว้แล้ว โดยสามารถไปแจ้งว่าเป็นครอบครัวของพนักงาน Dentsu กับทางโรงพยาบาลได้เลย แต่พนักงานต้องรับผิดชอบค่าใช้จ่ายส่วนนี้เอง')
          }
        },
        eClaim: {
          detail: {
            form: {
              nameRequest: text('Name Request', 'ชื่อผู้ขอเบิก'),
              claimFor: text('Claim For', 'ขอเบิกให้กับ'),
              amountReceipt: text('Amount Receipt', 'จำนวนเงินในใบเสร็จ'),
              claimAmount: text('Claim Amount', 'จำนวนเงินที่สามารถเบิกได้จริง'),
              inputAmount: text('Input Amount', 'กรอกจำนวนเงินที่ขอเบิก'),
              attachDocuments: text('Attach Documents', 'แนบเอกสาร'),
              receipt: text('Receipt', 'ใบเสร็จรับเงิน/ใบกำกับภาษี'),
              optionalFile: text('Optional File', 'เอกสารเพิ่มเติม'),
              pleaseSelect: text('Please Select', 'กรุณาเลือก'),
              next: text('Next', 'ถัดไป'),
              checkDetail: text('Check Detail', 'ตรวจสอบรายละเอียด'),
              title: text('กรอกรายละเอียดการเบิกสวัสดิการ', 'กรอกรายละเอียดการเบิกสวัสดิการ'),
              submit: text('Submit', 'ส่งคำขอเบิก'),
            }
          },
          history: {
            title: text('Claim Record', 'ประวัติการเบิก'),
            docNo: text('Document No.', 'เลขทำรายการ'),
            detail: text('Detail', 'รายละเอียด'),
            dateClaim: text('Date of Claim', 'วันที่เบิก'),
            dateApproveReject: text('Date of Approval/Reject', 'วันที่อนุมัติ/ปฏิเสธ'),
            dateReceiveMoney: text('Date of Receive Money', 'วันที่ได้รับเงิน'),
            cancelClaim: text('Cancel Claim', 'ยกเลิกการเบิก'),
            claimAgain: text('Claim Again', 'เบิกใหม่อีกครั้ง'),
            status: {
              title: text('Status', 'สถานะ'),
              pending: text('Pending', 'รอการอนุมัติ'),
              approved: text('Approved', 'อนุมัติเรียบร้อย'),
              rejected: text('Rejected', 'ไม่ผ่านการอนุมัติ'),
              waitDocument: text('Waiting for Documents', 'รอเอกสาร'),
              receiveDocument: text('Received Documents', 'ได้รับเอกสารแล้ว'),
              cancel: text('Canceled', 'ยกเลิกการเบิก'),
            },
            remarkByReject: text('Rejected reason', 'เหตุผลที่ปฏิเสธ'),
          },
          claimPriceError: text('Cannot greather than claim price', 'ต้องไม่มากกว่า Claim Price'),
          coinError: text(
            'Cannot greather than User coin',
            'จำนวนที่ขอเบิกต้องไม่มากกว่าเหรียญที่มี',
          ),
          name: text('name', 'ชื่อ'),
          status: text('status', 'สถานะ'),
          historyClaimButton: text('History', 'ประวัติการเบิก'),
          claimItemTitle: text('Claim Items', 'รายการสวัสดิการที่ต้องการเบิก'),
        },
        relative: {
          statusType: {
            approved: text('Approved', 'อนุมัติ'),
            pending: text('Pending', 'รอดำเนินการ'),
            rejected: text('Rejected', 'ไม่อนุมัติ'),
          }
        }
      },
    },
  }
}
