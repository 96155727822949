export enum Code {
  OPD = 'opd',
  FLEXI = 'flexi',
}

export type ClaimTypeGroup = {
  id: number;
  code: Code;
  nameTh: string;
  nameEn: string;
  descriptionTh: string;
  descriptionEn: string;
  icon: string;
  createdDate: number;
  createdBy: string;
  updatedDate: number;
  updatedBy: string;
  enable: boolean;
  claimTypes: any[];
}

export type ClaimTypeGroupList = ClaimTypeGroup[]

export type ClaimTypeGroupListResponse = ClaimTypeGroupList