import styled from '@emotion/styled'
import { message } from 'antd'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../components/common/Button'
import Modal, { ModalProps } from '../../components/common/Modal'
import Text from '../../components/common/Text'
import color from '../../constant/colors'
import { mobile } from '../../utils/responsive-helper'
import { useGetUser } from '../../services/user/user-query'
import RadioComp from '../../components/common/Radio'
import { useGetEnrollmentActivePeriod, useGetEnrollmentSelectPeriod, useSendConsent } from '../../services/enrollment/enrollment-query'
import dayjs from 'dayjs'

const Layout = styled.div`
  padding: 16px;
  ${mobile} {
    width: calc(100vw - 32px);
    padding: 16px;
    padding-top: 24px;
    .header {
      padding-right: 32px;
    }
  }
`

const Header = styled.div`
  text-align: center;
  margin-bottom: 16px;
`

const Content = styled.div`
  margin-bottom: 16px;
  border-radius: 8px;
  ${mobile} {
    padding: 16px;
  }
`

const ContentScroll = styled.div`
  height: calc(55vh - 48px);
  max-width: 784px;
  overflow-y: auto;

  ${mobile} {
    max-width: 100%;
  }
`
const ContentDetail = styled.div`
  padding: 0 48px;

  ${mobile} {
    padding: 0;
  }
`

export const Footer = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: ${color.WHITE};
  padding: 16px;
  border-radius: 5px;
  .check-box {
    display: flex;
    align-self: flex-start;
    align-items: flex-start;
    margin-bottom: 32px;

    .ant-checkbox {
      /* margin-top: 6px; */
      background-color: ${color.PRIMARY};
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: ${color.PRIMARY};
    }
    .ant-checkbox,
    .ant-checkbox-checked::after,
    .ant-checkbox-input + .ant-checkbox-inner,
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: ${color.PRIMARY};
      border-radius: 2px;
      width: 24px;
      height: 24px;
    }
    .ant-checkbox-checked .ant-checkbox-inner::after {
      top: 58%;
      left: 30%;
      transform: rotate(45deg) scale(1.5) translate(-50%, -50%);
    }
  }
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0 !important;
  }

  ${mobile} {
    .check-box {
      margin-bottom: 16px;
    }
  }
`

type ModalAcceptPrivacyProps = {
  onConfirmClick: () => void
  onCloseClick: () => void
} & Required<Pick<ModalProps, 'visible'>>
const ModalAcceptPrivacy = (props: ModalAcceptPrivacyProps) => {
  const { t } = useTranslation()
  const { visible, onConfirmClick, onCloseClick } = props
  const [isAccept, setIsAccept] = useState('1')
  const { data: User } = useGetUser()
  const { data: enrollmentSelectPeriod } = useGetEnrollmentSelectPeriod()
  const { mutate: sendConsent } = useSendConsent()
  const onCancel = useCallback(() => {
    onCloseClick()
    setIsAccept('')
  }, [onCloseClick])

  const onConfirm = useCallback(() => {
    sendConsent({
      isConsent: isAccept === '1',
      benefitYearId: 1
    }, {
      onSuccess: () => {
        onConfirmClick()
        setIsAccept('')
      },
      onError: (error: any) => {
        console.error(error.message)
        message.error('เกิดข้อผิดพลาดในการบันทึกข้อมูล (Consent)')
      }
    })
  }, [onConfirmClick, isAccept, sendConsent])

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      style={{
        padding: 0,
      }}
      hideFooter
    >
      <Layout>
        <Header>
          <Text size={20} type="Bold">
            {/* {t('enrollment.modalAcceptPrivacy.header')} */}
            หนังสือแสดงความยินยอมรูปแบบอิเล็กทรอนิกส์ (e-Consent Form)
          </Text>
          <Text size={20} type='Bold'>
            ของพนักงานบริษัท กรุงเทพ ซินธิติกส์ จำกัด และ บริษัท บีเอสที อิลาสโตเมอร์ส จำกัด
          </Text>
          <Text size={20} type='Bold'>
            เรื่อง ประสงค์ขอเปลี่ยนแปลงการรับสิทธิประโยชน์ แผนสวัสดิการยืดหยุ่น (FLEXi Package)
          </Text>
        </Header>
        <Content>
          <ContentScroll>
            <Text style={{ textIndent: 40, marginBottom: 8 }}>
              ข้าพเจ้า {`${User?.firstNameTH} ${User?.lastNameTH}`} มีความประสงค์ขอเปลี่ยนแปลงการรับสิทธิประโยชน์ตามรายการสวัสดิการด้านสุขภาพ (Health and Wellness) ที่ได้รับอยู่เดิมจากบริษัทฯ บางรายการ ดังนี้<br />
            </Text>
            <ContentDetail>
              <Text>
                1. <strong>เงินช่วยเหลือค่าสมาชิกสถานออกกำลังกาย/กีฬา:</strong><br />
                - พนักงานประจำสำนักงานกรุงเทพฯ เบิกค่าใช้จ่ายได้ตามจริงไม่เกิน 1,500 บาท/เดือน<br />
                - พนักงานประจำโรงงานระยองที่ลงชื่อใช้สถานออกกำลังกายในเครือข่าย เล่นได้ไม่จำกัดจำนวนครั้ง<br />
                - พนักงานประจำโรงงานระยองที่ลงชื่อใช้สถานออกกำลังกายนอกเครือข่าย เบิกค่าใช้จ่ายได้ตามจริงไม่เกิน 500 บาท/เดือน<br />
                2. <strong>เงินช่วยเหลือวัคซีนป้องกันโรคไวรัสตับอักเสบเอบี:</strong> บริษัทฯ ช่วยเหลือค่าวัคซีน 50% ของค่าใช้จ่ายจริง จำกัดคนละ 1 ครั้งตลอดอายุงานในบริษัทฯ (ไม่รวมค่าใช้จ่ายอื่นๆ ที่เกี่ยวข้อง)<br />
                3. <strong>วัคซีนป้องกันโรคไข้หวัดใหญ่:</strong> บริษัทฯ จัดหาให้สำหรับพนักงานที่สมัครใจรับวัคซีนปีละ 1 ครั้งโดยไม่มีค่าใช้จ่าย<br />
              </Text>
            </ContentDetail>
            <Text style={{ textIndent: 40, marginTop: 8 }}>
              ข้าพเจ้าประสงค์ขอสิ้นสุดการรับสิทธิประโยชน์ตามรายการสวัสดิการข้อ 1-3 ที่ระบุไว้ตั้งแต่วันที่ 1 มกราคม พ.ศ. {dayjs(enrollmentSelectPeriod?.startPeriodDate).add(543, 'year').format('YY')} เพื่อขอรับสิทธิประโยชน์จากบริษัทฯ เป็นรูปแบบ แผนสวัสดิการยืดหยุ่น (FLEXi Package) และให้มีผลบังคับใช้ตั้งแต่วันที่ 1 มกราคม พ.ศ. {dayjs(enrollmentSelectPeriod?.startPeriodDate).add(543, 'year').format('YY')} เป็นต้นไป ซึ่งเป็นไปตามรายการสวัสดิการ FLEXi ที่บริษัทฯ กำหนดไว้*<br />
            </Text>
            <Text style={{ textIndent: 40, marginTop: 8 }}>
              กรณี coin ไม่เพียงพอต่อการซื้อค่าประกันกลุ่มประเภท premium ข้าพเจ้า ขอให้ความยินยอมกับทางบริษัทฯ ในการหักค่าจ้างจากบัญชีเงินเดือนของข้าพเจ้า เพื่อจ่ายชำระคืนให้กับทางบริษัทฯ ตามจำนวนเงินที่บริษัทฯ ได้สำรองจ่ายค่าประกันกลุ่มที่ข้าพเจ้าเลือกในสวัสดิการflexi ให้แก่บริษัทฯ ประกันแทนข้าพเจ้า<br /><br />
            </Text>
            <Text style={{ textIndent: 40, marginTop: 8 }}>
              หมายเหตุ: บริษัทฯ ขอสงวนสิทธิในการเปลี่ยนแปลงสิทธิประโยชน์ต่างๆ ที่อยู่นอกเหนือจากที่กฎหมายว่าด้วยการคุ้มครองแรงงานกำหนดไว้ ตามความเหมาะสม
            </Text>
          </ContentScroll>
        </Content>
        <Footer>
          <RadioComp
            options={
              [
                {
                  label: 'ข้าพเจ้าประสงค์ขอเปลี่ยนแปลงการรับสิทธิประโยชน์ แผนสวัสดิการยืดหยุ่น (FLEXi Package) ตามรายละเอียดข้างต้น',
                  value: 1
                },
                {
                  label: 'ข้าพเจ้าไม่ประสงค์ขอเปลี่ยนแปลงการรับสิทธิประโยชน์ใดๆ',
                  value: 0
                }
              ]
            }
            value={isAccept}
            setValue={setIsAccept}
          />
          {/* <Checkbox
            className="check-box"
            checked={isAccept}
            onChange={(v) => {
              setIsAccept((prev) => !prev)
            }}
            autoFocus={true}
          >
            <Text>ข้าพเจ้าประสงค์ขอเปลี่ยนแปลงการรับสิทธิประโยชน์ แผนสวัสดิการยืดหยุ่น (FLEXi Package) ตามรายละเอียดข้างต้น</Text>
          </Checkbox>
          <Checkbox
            className="check-box"
            checked={isAccept2}
            onChange={(v) => {
              setIsAccept2((prev) => !prev)
            }}
            autoFocus={true}
          >
            <Text>ข้าพเจ้าไม่ประสงค์ขอเปลี่ยนแปลงการรับสิทธิประโยชน์ใดๆ</Text>
          </Checkbox> */}
          <Button onClick={onConfirm} disabled={!Number(isAccept)}>
            {t('enrollment.modalAcceptPrivacy.confirm')}
          </Button>
        </Footer>
      </Layout>
    </Modal >
  )
}

export default ModalAcceptPrivacy
