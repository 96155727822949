import { createSlice } from '@reduxjs/toolkit'
import dayjs, { Dayjs } from 'dayjs'
import { UserClaimDocumentList } from '../services/e-claim/e-claim-types'

export type EClaimInfoWithConcentDate = {
  consentDate: Dayjs;
};

export type EClaimInfoWithoutConcentDate = {
  id?: number
  name: string
  amountReceipt: number
  amountClaim: number
  receiptFile: string
  optionalFileUrl: string
  relativeTypeId: string
  receiptDate: number
  slipNo?: string
  claimName: string
};
export type EClaimInf = {
  claimInfo: EClaimInfoWithConcentDate | EClaimInfoWithoutConcentDate
}

export const initState: EClaimInf = {
  claimInfo: {
    name: '',
    amountReceipt: 0,
    amountClaim: 0,
    receiptFile: '',
    optionalFileUrl: '',
    relativeTypeId: '',
    receiptDate: 0,
    claimName: '',
  },
}

const eClaimSlice = createSlice({
  name: 'eClaim',
  initialState: initState,
  reducers: {
    setClaimInfo: (state, { payload }: PayloadWithType<EClaimInf['claimInfo']>) => {
      state.claimInfo = payload
    },
    clearClaimInfo: (state) => {
      state.claimInfo = initState.claimInfo
    },
  },
})

export const eClaimReducer = eClaimSlice.reducer

export const { setClaimInfo, clearClaimInfo } = eClaimSlice.actions
