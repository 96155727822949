import styled from '@emotion/styled'
import { Collapse } from 'antd'
import { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import WellBeingCardScg from '../../components/WellBeingCardScg'
import Button from '../../components/common/Button'
import Image from '../../components/common/Image'
import Modal from '../../components/common/Modal'
import Text from '../../components/common/Text'
import config from '../../config'
import { default as colors } from '../../constant/colors'
import { opdDetail } from '../../constant/path'
import { useGetOpdList } from '../../services/opd/opd-query'
import { EClaimListResponse } from '../../services/e-claim/e-claim-types'
import { useVisibility } from '../../utils/custom-hook'
import { mobile, mobileVerySmall, tablet, useScreen } from '../../utils/responsive-helper'
import { CollapseWellbeing } from './component'
import { OPDItem } from '../../services/opd/opd-types'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { setOpdInfo } from '../../redux-store/opd-reducer'
import dayjs from 'dayjs'

const { Panel } = Collapse

const WellBeingContainer = styled.div<{ isMobile: boolean }>`
  /* border: 1px solid #000000; */
  margin-left: 0px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
  justify-content: ${(isMobile) => (isMobile ? 'center' : 'flex-start')};
  margin-bottom: 15px;
  ${mobile} {
    grid-template-columns: 50% 50%;
    grid-gap: 8px;
  }
  ${mobileVerySmall} {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
`

const LayoutHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 8px;
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.1);
  border: 0.5px solid #DDDDDD;
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  user-select: none;
  background-color: ${colors.WHITE}

  :hover {
    cursor: pointer;
  }

  div:first-of-type {
    display: flex;
    column-gap: 10px;
  }

  ${tablet} {
    width: 100%;
  }
`
const ImageStyle = styled(Image)`
  object-fit: none;
  align-self: center;
`

const OpdListComponent = () => {
  const { i18n } = useTranslation()
  const { isMobile } = useScreen()
  const history = useHistory()
  const [visibleDialogMessage, modalActionDialogMessage] = useVisibility()
  const [visibleConcent, modalActionConcent] = useVisibility()
  const [OpdItem, setOpdItem] = useState<OPDItem>()
  const dispatch = useDispatch()

  const { data: opdList } = useGetOpdList()

  const onClickItems = useCallback((OPDItem: OPDItem) => () => {
    setOpdItem(OPDItem)
    if (OPDItem?.showDialog) {
      modalActionDialogMessage.show()
    } else {
      modalActionConcent.show()
    }
  }, [modalActionDialogMessage, modalActionConcent])

  const onClickDialogMessage = useCallback(() => {
    modalActionDialogMessage.hide()
    modalActionConcent.show()
  }, [modalActionDialogMessage, modalActionConcent])

  const onCancelConcent = useCallback(() => {
    history.push('/')
  }, [history])

  const onSubmit = useCallback(() => {
    if (OpdItem?.id) {
      dispatch(
        setOpdInfo({
          consentDate: dayjs(),
        }),
      )

      history.push(opdDetail({
        routeParam: {
          opdId: OpdItem.id,
        }
      }))
    }
  }, [OpdItem?.id, history, dispatch])


  return (
    <>
      <Modal
        visible={visibleDialogMessage}
        onCancel={() => {
          modalActionDialogMessage.hide()
        }}
        hideFooter
        style={{ maxWidth: 750 }}
      >
        <div style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          rowGap: 24,
        }}>
          <Image imageName='iconAlertPrivacy' />
          <Text size={24}>
            {OpdItem?.showDialogMessage?.includes('<!DOCTYPE html>') ? <div dangerouslySetInnerHTML={{ __html: OpdItem?.showDialogMessage }}></div> : OpdItem?.showDialogMessage}
          </Text>
          <Button onClick={onClickDialogMessage}>ตกลง</Button>
        </div>
      </Modal>
      <Modal
        visible={visibleConcent}
        onCancel={() => {
          onCancelConcent()
        }}
        hideFooter
        hideCloseIcon
        style={{ maxWidth: 750 }}
      >
        <div style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          rowGap: 24,
        }}>
          <Text size={18}>
            บริษัท กรุงเทพ ซินธิติกส์ จำกัด / บริษัท บีเอสที อิลาสโตเมอร์ส จำกัด (“บริษัทฯ”) มีความจำเป็นต้องเก็บ รวบรวม ใช้ วิเคราะห์ และเปิดเผยข้อมูลส่วนบุคคล รวมถึงข้อมูลสุขภาพที่เกี่ยวข้อง เพื่อวัตถุประสงค์ในการจัดสวัสดิการและเบิกจ่ายค่ารักษาพยาบาลให้กับพนักงานและผู้ที่มีสิทธิตามที่บริษัทฯ กำหนด
            ทั้งนี้ ข้าพเจ้าได้อ่านรายละเอียดนโยบายความเป็นส่วนตัวของบริษัทฯ หรือได้รับคำอธิบายจากบริษัทฯ ถึงวัตถุประสงค์ในการเก็บ รวบรวม ใช้หรือเปิดเผยข้อมูลส่วนบุคคล และมีความเข้าใจดีแล้ว ข้าพเจ้าจึงให้ความยินยอมหรือปฏิเสธไม่ให้ความยินยอมข้างต้น ด้วยความสมัครใจ
          </Text>
          <div style={{
            display: 'flex',
            columnGap: 24,
          }}>
            <Button onClick={onSubmit}>ยินยอม</Button>
            <Button background={colors.WHITE} fontColor={colors.CHOICES_PRIMARY} onClick={onCancelConcent}>ไม่ยินยอม</Button>
          </div>
        </div>
      </Modal>

      <CollapseWellbeing
        style={{
          margin: isMobile ? '0px 16px 0px' : '0px 0px 0px',
        }}
      >
        {opdList?.map((val, index) => {
          const { claimList } = val
          return (
            <Panel
              key={index}
              style={{ marginBottom: '14px' }}
              header={
                <LayoutHeader>
                  <div>
                    <ImageStyle width={24} height={24} imageName={'IconInsurance'} />
                    <Text type="Bold" size={14}>
                      {i18n.language === 'en' ? val.nameEn : val.nameTh}
                    </Text>
                  </div>
                  <Text size={10} type='Medium' color={'#666666'}>{i18n.language === 'en' ? val.descriptionEn : val.descriptionTh}</Text>
                </LayoutHeader>
              }
            >
              <div style={{ backgroundColor: '#ECECEC', padding: 16 }}>
                <WellBeingContainer isMobile={isMobile}>
                  {claimList?.map((item: OPDItem) => {
                    return (
                      <WellBeingCardScg
                        title={item.nameEn}
                        description={item.name}
                        icon={item.icon && `${config.apiHost}/files${item.icon}`}
                        maxClaimablePrice={item.maxClaimableLimit}
                        availableAmount={item.allowedAmount}
                        onClick={onClickItems(item)}
                      />
                    )
                  })}
                </WellBeingContainer>
              </div>
            </Panel >

          )
        })}
      </CollapseWellbeing >
    </>
  )
}

export default OpdListComponent