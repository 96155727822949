import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { api } from '../../utils/api'
import { ContentType } from '../../utils/api/tools'
import { GET_RELATIVE_SCG } from '../e-claim/e-claim-query'
import { CreateEclaimUserResponse, EClaimItem, GetEClaimByIdProps, RelativeScgResponse } from '../e-claim/e-claim-types'
import { GET_USER_COIN_URL } from '../user/user-query'
import { OPDItem, ClinicListsResponse, CreateOpdUser, OPDHistoryListResponse, OpdListResponse } from './opd-types'
import { E_CLAIM_LIST, E_CLAIM_BY_ID } from '../e-claim/e-claim-query'

const APP = '/app'
export const USER_CLAIM = 'userClaim'
export const CHECK_CLAIM_AMOUNT = `${APP}/${USER_CLAIM}/claimableAmount/OPD`
export const CREATE_OPD_CLAIM = `${APP}/${USER_CLAIM}/createUserClaim/OPD`
export const CLINIC_LISTS = `${APP}/claim/medicalType`
export const OPD_HISTORY_LIST = `${APP}/${USER_CLAIM}/getAllUserClaimByUser`

export const useGetOpdById = (props: GetEClaimByIdProps) => {
  const { claimId } = props

  return useQuery([E_CLAIM_BY_ID, claimId], () => {
    return api.belive.get<EClaimItem>(`${E_CLAIM_BY_ID}/${claimId}`)
  })
}

export const useGetOpdList = (groupName = 'opd') => {
  return useQuery<OpdListResponse>([E_CLAIM_LIST, groupName], () => {
    return api.belive.get<OpdListResponse>(`${E_CLAIM_LIST}?claimTypeGroupName=${groupName}`)
  })
}

export const useCreateOpdClaim = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (data: CreateOpdUser) => {
      const {
        claimId,
        receiptAmount,
        receiptDate,
        relativeId,
        clinicType,
        consentDate,
        consentBy,
        userClaimDocumentList
      } = data
      return api.belive.post<CreateEclaimUserResponse>(
        CREATE_OPD_CLAIM,
        JSON.stringify({
          claimId,
          receiptAmount,
          receiptDate,
          relativeId,
          clinicType,
          consentDate,
          consentBy,
          userClaimDocumentList
        }),
        { contentType: ContentType.JSON },
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([E_CLAIM_BY_ID])
        queryClient.invalidateQueries([GET_USER_COIN_URL])
        queryClient.invalidateQueries([OPD_HISTORY_LIST])
      },
      onError: (e: any) => {
        if (e.message) {
          console.log(e.message)
        }
      },
    },
  )
}

export const useGetClinicLists = (claimId?: number) => {
  return useQuery([CLINIC_LISTS, claimId], () => {
    return api.belive.get<ClinicListsResponse>(`${CLINIC_LISTS}/${claimId}`)
  }, {
    enabled: !!claimId,
  })
}
export const useGetOpdHistoryList = (groupCode = 'opd') => {
  return useQuery([OPD_HISTORY_LIST, groupCode], () => {
    return api.belive.get<OPDHistoryListResponse>(`${OPD_HISTORY_LIST}?claimTypeGroupCode=${groupCode}`)
  })
}

export const useGetRelativeOption = (claimId?: number) => {
  return useQuery([GET_RELATIVE_SCG, 'options', claimId], async () => {
    const data = await api.belive.get<RelativeScgResponse>(`${GET_RELATIVE_SCG}/opd/${claimId}`)
    const optionRelative: {
      label: string
      value: Record<string, string>
      disabled?: boolean
    }[] = []

    data.map((relative) => {
      const { firstname, lastname, id, typeId, typeName } = relative
      optionRelative.push({
        label: `${typeName}: ${firstname} ${lastname}`, value: {
          id: id.toString(),
          typeId: typeId.toString(),
        }
      })
    })
    // }

    return optionRelative
  })
}