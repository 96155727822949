import Breadcrumb from '../../components/common/Breadcrumb'
import styled from '@emotion/styled'
import OpdForm from './OpdForm'
import { useRouter } from '../../utils/helper'
import { useGetOpdById } from '../../services/opd/opd-query'
import EClaimInfo from '../EClaimDetail/EClaimInfo'
import Loading from '../../components/common/Loading'
import { eClaim, eClaimDetail, enrollment, home } from '../../constant/path'
import { mobile, useScreen } from '../../utils/responsive-helper'
import { useMemo } from 'react'
import Headbar from '../../components/common/HeadBar'
import * as paths from '../../constant/path'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux-store'
import { OpdInfoWithConcentDate, OpdInfoWithoutConcentDate } from '../../redux-store/opd-reducer'

const BackgroundHeader = styled.div`
  background-color: #E7E2D8;
  width: calc(100% - 32px);
  height: 266px;
  margin-top: 0px !important;
  position: absolute;

  ${mobile} {
    width: 100%;
  }
`
const Layout = styled.div`
  width: 768px;
  margin: 0 auto;
  z-index: 1;
  > * {
    margin-top: 24px;
  }

  ${mobile} {
    width: 100%;
  }
`

const OpdDetail = () => {
  const { query } = useRouter<{ opdId: number, claimTypeId: string, isCreateNew: 'true' | 'false' }>()

  const { isMobile } = useScreen()
  const { data: opdItem, isLoading } = useGetOpdById({ claimId: query.opdId })
  const initialValue = useMemo(() => { return { relativeTypeId: 0, } }, [])
  const OpdFormValue = useSelector((state: RootState) => state.opd.OpdInfo) as OpdInfoWithoutConcentDate & OpdInfoWithConcentDate

  return (
    <Loading loading={false}>
      <BackgroundHeader />
      <Layout>
        <Headbar title={opdItem?.name || ''} backWard={paths.opd()} />
        {/* <EClaimInfo eClaimItem={eClaimItem} /> */}

        {/*   <EClaimDocument /> */}

        <OpdForm opdItem={opdItem} opdFormValue={OpdFormValue} opdId={query.opdId} allpayStatus={false} isCreateNew={query.isCreateNew} />
      </Layout>
    </Loading>
  )
}

export default OpdDetail