import React, { useMemo, useCallback, useState } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { setSteps } from '../../../redux-store/enrollment-reducer'
import { RootState } from '../../../redux-store'
import { useDispatch } from 'react-redux'
import Button from '../../../components/common/Button'
import colors from '../../../constant/colors'
import Text from '../../../components/common/Text'
import Card from '../../../components/common/Card'
import { BenefitChoices, BenefitType } from '../../../services/enrollment/enrollment-types'
import { getFormValues } from 'redux-form'
import { numberWithCommas } from '../../../utils/helper'
import { useGetRelative, useGetRelativeBenefit } from '../../../services/relative/relative-query';
import { RelativeStatus } from '../../../services/relative/relative-typed';
import { message } from 'antd'
import { useHistory } from 'react-router'
import * as paths from '../../../constant/path'
import { mobile, useScreen } from '../../../utils/responsive-helper'
import { useSelfBenefitsSend } from '../../../services/enrollment/enrollment-query'
import { notification } from 'antd'
import Loading from '../../../components/common/Loading'
import { useGetEnrollmentSelectPeriod, useGetEnrollmentActivePeriod } from '../../../services/enrollment/enrollment-query'
import dayjs from 'dayjs'

const Layout = styled.div`
`
const CardLayout = styled(Card)`
  margin-bottom: 16px;
`
const Title = styled(Text)`
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
`
const SubTitle = styled(Text)`
  text-align: center;
  font-size: 18px;
  margin-bottom: 16px;
`
const Lists = styled.ol`
  padding: 0;
  margin: 0;
`
const ListItem = styled.li`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  column-gap: 8px;
`
const ButtonGroup = styled.div`
  display: flex;
  column-gap: 16px;
  justify-content: flex-end;
`
const ButtonStyle = styled(Button)`
  width: fit-content;
  min-width: 120px;
`
const Table = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`
const TableHeader = styled(Text)`
  grid-column: 1 / span 2;
`

const ENROLLMENT_FORM_NAME = 'Enrollment'
type EditSelfBenefitsFormValues = {
  [key: string]: BenefitChoices[]
}
const SummarySelect = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const [coin, setCoin] = useState(12000)
  const { data: enrollmentSelectPeriod, isFetched } = useGetEnrollmentSelectPeriod()
  const { data: enrollmentActivePeriod } = useGetEnrollmentActivePeriod()
  const isNotFoundBenefitYear = useMemo(() => {
    if (isFetched && enrollmentSelectPeriod) return false

    return true
  }, [enrollmentSelectPeriod, isFetched])
  const { data } = useGetRelativeBenefit(isNotFoundBenefitYear ? enrollmentActivePeriod?.benefitYearId : enrollmentSelectPeriod?.benefitYearId)
  const { mutate: sendSelfBenefits, isLoading } = useSelfBenefitsSend()

  const marriedCoupleAndChild = useMemo(() => {
    return data?.filter((item) => !['มารดา', 'บิดา'].includes(item.relativeType.name));
  }, [data]);

  const parents = useMemo(() => {
    return data?.filter((item) => ['มารดา', 'บิดา'].includes(item.relativeType.name));
  }, [data]);

  const familyStatus = useCallback((status) => {
    switch (status) {
      case RelativeStatus.PENDING:
        return 'รอการอนุมัติ'
      case RelativeStatus.APPROVED:
        return 'อนุมัติแล้ว'
      default:
        return 'รอการอนุมัติ'
    }
  }, [])

  const formValuesSelector = getFormValues(ENROLLMENT_FORM_NAME)
  const formValues = useSelector<any, EditSelfBenefitsFormValues>((state) => formValuesSelector(state))

  const steps = useSelector(
    (state: RootState) => state.enrollment.steps,
  )
  const { current, total } = steps

  // const next = () => {
  //   dispatch(
  //     setSteps({
  //       ...steps,
  //       current: current + 1,
  //     }),
  //   )
  // }

  const prev = () => {
    dispatch(
      setSteps({
        ...steps,
        current: current - 1,
      }),
    )
  }

  const selectedChoicesBenefit = useMemo(() => {
    return Object.keys(formValues || {})
      .map((key) => formValues[key][0])
      .map((v, index) => (v))[0]
  }, [formValues])

  const selectedChoicesFamily = useMemo(() => {
    return Object.keys(formValues || {})
      .map((key) => formValues[key][0])
      .map((v, index) => (v))[1]
  }, [formValues])

  const totalSelectedPoint = useMemo(() => {
    return formValues
      ? Object.entries(formValues).reduce((sumTotalPoint, cur) => {
        const [, value] = cur
        value.forEach((choiceSelected) => {
          const point = choiceSelected?.taggedPrice || 0
          sumTotalPoint += point
        })
        return sumTotalPoint
      }, 0)
      : 0
  }, [formValues])

  const remainPoint = useMemo(() => {
    return coin -
      (selectedChoicesBenefit?.taggedPrice < 0 ? (selectedChoicesBenefit?.taggedPrice * -1) : 0) -
      (selectedChoicesFamily?.taggedPrice < 0 ? (selectedChoicesFamily?.taggedPrice * -1) : 0)
  }, [coin, selectedChoicesBenefit, selectedChoicesFamily])

  const isFlexFamily = useMemo(() => {
    if (typeof selectedChoicesFamily === 'undefined') return false
    if (selectedChoicesFamily?.taggedPrice === 0) return false

    return true
  }, [selectedChoicesFamily])

  const onSubmit = useCallback(async () => {
    if (!enrollmentSelectPeriod) return

    const data = []

    if (selectedChoicesBenefit) data.push(selectedChoicesBenefit.id)
    if (selectedChoicesFamily) data.push(selectedChoicesFamily.id)

    sendSelfBenefits({
      benefitYearId: enrollmentSelectPeriod.benefitYearId,
      selectChoiceId: data
    }, {
      onSuccess: () => {
        history.push(paths.enrollmentComplete({
          queryParam: {
            benefitType: BenefitType.FLEXi,
            myBenefitPlan: selectedChoicesBenefit?.choicesName,
            benefitFamily: selectedChoicesFamily?.choicesName,
          }
        }))
      },
      onError: (error: any) => {
        if (error.message === 'Cannot select same choice') {
          history.push(paths.enrollmentComplete({
            queryParam: {
              benefitType: BenefitType.FLEXi,
              myBenefitPlan: selectedChoicesBenefit?.choicesName,
              benefitFamily: selectedChoicesFamily?.choicesName,
            }
          }))
        } else {
          console.error(error)
          message.error(error.message)
        }
      }
    })

  }, [history, selectedChoicesBenefit, selectedChoicesFamily, sendSelfBenefits, enrollmentSelectPeriod])

  return (
    <Loading loading={isLoading}>
      <Layout>
        <Title>แผนสวัสดิการของคุณ</Title>
        <SubTitle>FLEXi Package</SubTitle>
        <CardLayout>
          <Title>แผนประกันสุขภาพของคุณ (FLEXi Insurance)</Title>
          <SubTitle>{selectedChoicesBenefit?.choicesName}</SubTitle>
        </CardLayout>
        <CardLayout>
          <Title>แลกบัตรประกันคู่สมรส-บุตร</Title>
          {isFlexFamily ? (
            <>
              <SubTitle>คุณประสงค์แลกบัตรประกันสุขภาพให้คู่สมรส-บุตร</SubTitle>
              <Lists>
                {marriedCoupleAndChild?.map((item) => (
                  <ListItem>
                    <Text>
                      ชื่อ <strong>{`${item.firstname} ${item.lastname}`}</strong> ความสัมพันธ์ <strong>{item.relativeType.name}</strong> สถานะเอกสาร: <strong>{familyStatus(item.status)}</strong>
                    </Text>
                  </ListItem>
                ))}
              </Lists>
            </>
          ) : (
            <SubTitle>คุณประสงค์ไม่แลกบัตรประกันสุขภาพให้คู่สมรส-บุตร</SubTitle>
          )}
        </CardLayout>
        <CardLayout>
          <Title>สรุป FLEXi Coin</Title>
          <Table>
            <TableHeader>FLEXi Coin ที่หักเพิ่มจากการเลือกแผนประกันสุขภาพ FLEXi Insurance</TableHeader>
            <Text color={colors.DARK_RED}>{selectedChoicesBenefit?.taggedPrice < 0 ? numberWithCommas(selectedChoicesBenefit?.taggedPrice * -1) : 0}</Text>
            <TableHeader>FLEXi Coin ที่หักเพิ่มจากการแลกบัตรประกันคู่สมรส-บุตร</TableHeader>
            <Text color={colors.DARK_RED}>{numberWithCommas(selectedChoicesFamily?.taggedPrice * -1) || 0}</Text>
            <TableHeader>ค่าใช้จ่ายส่วนต่างที่พนักงานต้องชำระเงินเพิ่ม (หักจากเงินเดือน ก.ค. {dayjs(enrollmentSelectPeriod?.startPeriodDate).add(543, 'year').format('YY')}):</TableHeader>
            <Text color={colors.DARK_RED}>{remainPoint < 0 ? numberWithCommas(remainPoint * -1) : 0}</Text>
            <TableHeader>FLEXi Coin ที่ได้รับจากการเลือก Small Plan (คืน coin เข้าระบบ {dayjs(enrollmentSelectPeriod?.startPeriodDate).add(543, 'year').format('D MMM YY')})</TableHeader>
            <Text color={colors.LIGHT_BLUE}>{selectedChoicesBenefit?.taggedPrice > 0 ? numberWithCommas(selectedChoicesBenefit?.taggedPrice || 0) : 0}</Text>
            <TableHeader>FLEXi Coin คงเหลือ</TableHeader>
            <Text color={colors.YELLOW_STAR}>{remainPoint > 0 ? numberWithCommas(remainPoint) : 0}</Text>
          </Table>
        </CardLayout>
        <CardLayout>
          <Title>ข้อมูลบิดามารดา (สำหรับกรณีไม่แลกบัตรประกันคู่สมรส-บุตร)</Title>
          {isFlexFamily ? (
            <SubTitle>คุณใช้สิทธิแลกบัตรประกันคู่สมรส-บุตรแล้ว</SubTitle>
          ) : (
            <>
              <SubTitle>
                {
                  parents && parents?.length > 0 ? (
                    'คุณลงทะเบียนครอบครัวสำหรับเบิกรายการสวัสดิการ FLEXi Item'
                  )
                    : (
                      'คุณยังไม่ได้ลงทะเบียนบิดา-มารดาสำหรับเบิกรายการสวัสดิการ FLEXi Item'
                    )
                }
              </SubTitle>
              <Lists>
                {parents?.map((item) => (
                  <ListItem>
                    <Text>
                      ชื่อ <strong>{`${item.firstname} ${item.lastname}`}</strong> ความสัมพันธ์ <strong>{item.relativeType.name}</strong> สถานะเอกสาร: <strong>{familyStatus(item.status)}</strong>
                    </Text>
                  </ListItem>
                ))}
              </Lists>
            </>
          )}
        </CardLayout>
        <ButtonGroup>
          <ButtonStyle onClick={prev} fontColor={colors.CHOICES_PRIMARY} style={{ background: colors.WHITE }}>ย้อนกลับ</ButtonStyle>
          <ButtonStyle onClick={onSubmit}>ยืนยัน</ButtonStyle>
        </ButtonGroup>
      </Layout>
    </Loading>
  )
}

export default SummarySelect