import React, { useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { getFormValues } from 'redux-form'
import { useSelector } from 'react-redux'
import { message } from 'antd';
import { mobile, useScreen } from '../../../utils/responsive-helper'
import { numberWithCommas } from '../../../utils/helper'
import color from '../../../constant/colors'
import Text from '../../../components/common/Text'
import Button from '../../../components/common/Button'
import { BenefitChoices } from '../../../services/enrollment/enrollment-types'
import { RootState } from '../../../redux-store'
import { useDispatch } from 'react-redux'
import { setSteps } from '../../../redux-store/enrollment-reducer'
import colors from '../../../constant/colors'
import { useGetEnrollmentSelectPeriod } from '../../../services/enrollment/enrollment-query'
import dayjs from 'dayjs'

const FooterContentLayout = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding: 16px 24px;
  border: 1px solid #E5E5E5;

  .left {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
  ${mobile} {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    .right {
      width: 100%;
    }
  }
`
const Row = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
`
const SummaryPoint = styled(Row)`
  flex-wrap: wrap;
  > *:not(:last-child) {
    margin-right: 8px;
  }

  ${mobile} {
    margin-bottom: 16px;
  }
`
const ButtonGroup = styled.div`
  display: flex;
  column-gap: 16px;
`
const ButtonStyle = styled(Button)`
  width: 150px;
  height: 45px;
  align-self: flex-end;

  &:hover, focus, active {
    border-color: ${color.CHOICES_PRIMARY} !important;
  }

  ${mobile} {
    margin: auto;
    width: 100%;
  }
`
const Table = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  
  ${mobile} {
    grid-template-columns: 1fr;
    grid-gap: 8px;
  }
`
const TableHeader = styled(Text)`
  grid-column: 1 / span 2;

  ${mobile} {
    grid-column: 1 / span 1;
  }
`

const ENROLLMENT_FORM_NAME = 'Enrollment'
type EditSelfBenefitsFormValues = {
  [key: string]: BenefitChoices[]
}

type FooterContentProps = {
  onSubmit: (next: () => void, values?: number) => void
  isStep?: boolean
  isFamily?: boolean
}

const FooterContent = (props: FooterContentProps) => {
  const { onSubmit, isStep = false, isFamily = true } = props
  const { t } = useTranslation()
  const { isMobile } = useScreen()
  const dispatch = useDispatch()
  const [coin, setCoin] = useState(12000)
  const { data: enrollmentSelectPeriod } = useGetEnrollmentSelectPeriod()

  const steps = useSelector(
    (state: RootState) => state.enrollment.steps,
  )
  const { current, total } = steps

  const next = () => {
    dispatch(
      setSteps({
        ...steps,
        current: current + 1,
      }),
    )
  }

  const prev = () => {
    dispatch(
      setSteps({
        ...steps,
        current: current - 1,
      }),
    )
  }

  const formValuesSelector = getFormValues(ENROLLMENT_FORM_NAME)
  const formValues = useSelector<any, EditSelfBenefitsFormValues>((state) => formValuesSelector(state))

  const selectedChoicesBenefit = useMemo(() => {
    return Object.keys(formValues || {})
      .map((key) => formValues[key][0])
      .map((v, index) => (v))[0]
  }, [formValues])

  const selectedChoicesFamily = useMemo(() => {
    return Object.keys(formValues || {})
      .map((key) => formValues[key][0])
      .map((v, index) => (v))[1]
  }, [formValues])

  const totalSelectedBenefit = useMemo(() => {
    const obj = Object.entries(formValues || {}).map((value, index) => ({
      title: value[0],
      data: value[1],
    }))

    return obj
  }, [formValues])

  const totalSelectedPoint = useMemo(() => {
    return formValues
      ? Object.entries(formValues).reduce((sumTotalPoint, cur) => {
        const [, value] = cur
        value.forEach((choiceSelected) => {
          const point = choiceSelected?.taggedPrice || 0
          sumTotalPoint += point
        })
        return sumTotalPoint
      }, 0)
      : 0
  }, [formValues])

  const remainPoint = useMemo(() => {
    return coin -
      (selectedChoicesBenefit?.taggedPrice < 0 ? (selectedChoicesBenefit?.taggedPrice * -1) : 0) -
      (selectedChoicesFamily?.taggedPrice < 0 ? (selectedChoicesFamily?.taggedPrice * -1) : 0)
  }, [coin, selectedChoicesBenefit, selectedChoicesFamily])

  const isFamilySelected = useMemo(() => {
    if (isFamily) {
      return !selectedChoicesFamily
    } else {
      return false
    }
  }, [selectedChoicesFamily, isFamily])

  const handleSubmit = () => {
    if (selectedChoicesBenefit && isFamilySelected) {
      onSubmit(next)
    } else if (selectedChoicesBenefit) {
      onSubmit(next)
    } else {
      message.error('Please select at least one benefit')
    }
  }

  if (!totalSelectedBenefit) return <>Loading...</>

  return (
    <FooterContentLayout>
      <div className="left">
        <Text size={14} type="Bold" style={{ textAlign: isMobile ? 'center' : 'start' }}>
          {t('enrollment.editSelfBenefits.summary.title')}
        </Text>
        <Table>
          <TableHeader type={isMobile ? 'SemiBold' : 'Regular'}>แผนประกันสุขภาพของคุณ : </TableHeader>
          <Text>{selectedChoicesBenefit?.choicesName}</Text>
          <TableHeader type={isMobile ? 'SemiBold' : 'Regular'}>FLEXi Coin ปัจจุบันของคุณ : </TableHeader>
          <Text color={colors.YELLOW_STAR}>12,000</Text>
          <TableHeader type={isMobile ? 'SemiBold' : 'Regular'}>FLEXi Coin ที่หักเพิ่มจากการเลือกแผนประกันสุขภาพ FLEXi Insurance : </TableHeader>
          <Text color={colors.DARK_RED}>{selectedChoicesBenefit?.taggedPrice < 0 ? numberWithCommas(selectedChoicesBenefit?.taggedPrice) : 0}</Text>
          <TableHeader type={isMobile ? 'SemiBold' : 'Regular'}>FLEXi Coin ที่หักเพิ่มจากการแลกบัตรประกันคู่สมรส-บุตร : </TableHeader>
          <Text color={colors.DARK_RED}>{numberWithCommas(selectedChoicesFamily?.taggedPrice) || 0}</Text>
          <TableHeader type={isMobile ? 'SemiBold' : 'Regular'}>ค่าใช้จ่ายส่วนต่างที่พนักงานต้องชำระเงินเพิ่ม (หักจากเงินเดือน ก.ค. {dayjs(enrollmentSelectPeriod?.startPeriodDate).add(543, 'year').format('YY')}) : </TableHeader>
          <Text color={colors.DARK_RED}>{remainPoint < 0 ? numberWithCommas(remainPoint * -1) : 0}</Text>
          <TableHeader type={isMobile ? 'SemiBold' : 'Regular'}>FLEXi Coin ที่ได้รับจากการเลือก Small Plan (คืน coin เข้าระบบ {dayjs(enrollmentSelectPeriod?.startPeriodDate).add(543, 'year').format('D MMM YY')}) : </TableHeader>
          <Text color={colors.LIGHT_BLUE}>{selectedChoicesBenefit?.taggedPrice > 0 ? numberWithCommas(selectedChoicesBenefit?.taggedPrice || 0) : 0}</Text>
          <TableHeader type={isMobile ? 'SemiBold' : 'Regular'}>FLEXi Coin คงเหลือ : </TableHeader>
          <Text color={colors.YELLOW_STAR}>{remainPoint > 0 ? numberWithCommas(remainPoint) : 0}</Text>
        </Table>

        {/* {totalSelectedBenefit
          ? totalSelectedBenefit.map((benefit, index) => (
            <Row
              key={index}
              style={{
                flexDirection: isMobile ? 'column' : 'row',
              }}
            >
              <Text size={14} style={{ textAlign: isMobile ? 'center' : 'start' }}>
                {t('enrollment.insurance')} :
              </Text>
              <Row
                style={{
                  flexWrap: 'wrap',
                }}
              >
                {benefit.data.map((data, index) => (
                  <>
                    <Text key={data.id} size={14}>
                      {`${data.choicesName} ${benefit.data.length === index + 1 ? '' : ','
                        } `}
                    </Text>
                  </>
                ))}
              </Row>
            </Row>
          ))
          : null} */}
      </div>
      {/* <div className="right">
        <Text
          size={16}
          color={color.FONT_BLACK}
          style={{ textAlign: isMobile ? 'center' : 'end', marginBottom: 8 }}
          type={isMobile ? 'Bold' : 'Regular'}
        >
          FLEXi Coins คงเหลือ
        </Text>
        <SummaryPoint
          style={{
            flexDirection: 'column',
            alignItems: isMobile ? 'center' : 'flex-end',
            marginBottom: 24,
          }}
        >
          <Text size={30} type="Bold" color={color.PRIMARY}>
            {numberWithCommas(totalSelectedPoint)}
          </Text>
        </SummaryPoint>
      </div> */}
      <ButtonStyle
        onClick={handleSubmit}
        disabled={!selectedChoicesBenefit || isFamilySelected}
        background={color.CHOICES_PRIMARY}
      >
        {t('app.continue')}
      </ButtonStyle>
    </FooterContentLayout>
  )
}

export default FooterContent