import qs from 'querystring'
import { BenefitType } from '../services/enrollment/enrollment-types'

type ParamType = { [p: string]: any } | undefined
type Option<T extends ParamType, V extends ParamType> = {
  routeParam?: T
  queryParam?: V
}

const generate =
  <T extends ParamType = undefined, V extends ParamType = undefined>(url: string) =>
    (option?: Option<T, V>) => {
      const { routeParam, queryParam } = option || {}
      let newQueryParam = ''
      if (queryParam) {
        newQueryParam = `?${qs.stringify(queryParam)}`
      }

      let newUrl = url
      if (routeParam) {
        const urls = url.split('/')
        newUrl = urls
          .map((u) => {
            if (/:.+/.test(u)) {
              return routeParam[u.slice(1)]
            }
            return u
          })
          .join('/')
      }

      return `${newUrl}${newQueryParam}`
    }

export const notFound = generate('/404')
export const login = generate('/login')
export const authCallback = generate<{ token: string }>('/saml/callback')
export const home = generate('/home')
export const news = generate<undefined, { type: string }>('/news')
export const newsDetails = generate<{ newsId: number }>('/news/:newsId')
export const play = generate<undefined, { type: string }>('/play')
export const playDetail = generate<{ playId: number }>('/play/:playId')

export const profile = generate(`${home()}/profile`)
export const about = generate(`${home()}/about`)
export const contact = generate(`${home()}/contact`)
export const termAndCondition = generate(`${home()}/term_and_condition`)
export const shop = generate('/shop')
export const shopUser = generate('/shop_user')
export const shopCart = generate(`${shopUser()}/cart`)
export const shopUserCoin = generate(`${shopUser()}/coin`)
export const promotion = generate('/promotion')
export const promotionCategory = generate('/promotion_categories')
export const promotionCategoryDetail = generate<{ promotionId: number }>(
  '/promotion_categories/:promotionId',
)
export const coupon = generate('/coupon')
export const couponDetail = generate<{ couponId: number }, { page: string }>('/coupon/:couponId')
export const products = generate('/products')
export const product = generate('/product')
export const productDetail = generate<{ productId: number }, { page: string }>(
  `/product/:productId`,
)
export const productDetailDescription = generate<{ productId: number }>(
  `/product/:productId/description`,
)
export const order = generate('/order')
export const shopBrand = generate<{ brandId: number }, { title: string }>('/brands/:brandId')
export const shopCategory = generate<{ categoryId: number }, { title: string }>(
  '/shop_categories/:categoryId',
)
export const categoryReward = generate<{ categoryId: number; rewardId: number }, { title: string }>(
  '/shop_categories/:categoryId/rewards/:rewardId',
)
export const brandReward = generate<{ brandId: number; rewardId: number }, { title: string }>(
  '/brands/:brandId/rewards/:rewardId',
)
export const claimCategoryReward = generate<{ categoryId: number; rewardId: number }>(
  '/shop_categories/:categoryId/rewards/:rewardId/claim_reward',
)
export const claimBrandReward = generate<{ brandId: number; rewardId: number }>(
  '/brands/:brandId/rewards/:rewardId/claim_reward',
)
export const notification = generate(`${home()}/notifcations`)
export const app = generate(`${home()}/app`)
export const pointHistory = generate(`${home()}/point_history`)

// My Benefit Page
export const enrollment = generate<undefined, { summary: string }>(`/enrollment`)
export const benefits = generate<{ benefitYearId: number }>(`${enrollment()}/self`)
export const benefit = generate<undefined, { id: number }>(`${enrollment()}/benefit`)
export const editSelfBenefits = generate<{ benefitYearId: number }, { benefitsName: string }>(
  `${benefits()}/edit`,
)
export const editRelativeBenefits = generate<
  { benefitYearId: number; relativeId: number },
  { benefitsName: string }
>(`${benefits()}/relatives/:relativeId/edit`)
export const enrollmentLeave = generate<{ benefitYearId: number }>(`${benefits()}/leave`)
export const enrollmentInsuranceFamily = generate<{ benefitYearId: number }>(
  `${benefits()}/insurance_family`,
)
export const insuranceFamilyForm = generate<
  { benefitYearId: number },
  { relativeTypeId: number; relativeId?: number; typeName: string }
>(`${benefits()}/insurance_family/form`)
export const enrollmentWellBeing = generate<{ benefitYearId: number }>(`${benefits()}/well-being`)
export const enrollmentWellBeingEdit = generate<{ benefitYearId: number; claimTypeId: string }>(
  `${benefits()}/well-being/:claimTypeId/edit`,
)

export const enrollmentTraditionalSummary = generate<undefined, { id: number }>(`${enrollment()}/traditional/summary`)
export const enrollmentComplete = generate<undefined, { benefitType: BenefitType, myBenefitPlan?: string, benefitFamily?: string }>(`${enrollment()}/complete`)

// E-Claim Move to Enrollment
export const eClaim = generate(`/e_claim`)
export const eClaimHistory = generate(`${eClaim()}/history`)
export const eClaimHistoryDetail = generate<{ id: number }>(`${eClaimHistory()}/:id`)
export const eClaimDetail = generate<{ eClaimId: number }, { isCreateNew?: 'true' | 'false' }>(`${eClaim()}/:eClaimId`)
export const eClaimSummary = generate<{ eClaimId: number }>(`${eClaimDetail()}/summary`)
export const eClaimCompleted = generate<
  { eClaimId: number },
  { userClaimId: number }
>(`${eClaimDetail()}/completed`)
// Relative 
export const relative = generate(`/relative`)
export const relativeAdd = generate(`${relative()}/add`)
export const relativeDetail = generate<{ relativeId: number }>(`${relative()}/:relativeId`)
export const relativeCompleted = generate<{ relativeId: number }>(`${relativeDetail()}/completed`)
export const family = generate(`/family`)
// Health Insurance
export const healthInsurance = generate(`/healthInsurance`)
export const healthInsurancePlan = generate<{ selfBenefitsChoiceId: number }>(`${healthInsurance()}/plan/:selfBenefitsChoiceId`)
export const healthInsuranceRelative = generate<{ relativeId: number }>(`${healthInsurance()}/relative/:relativeId`)
export const healthInsuranceCompleted = generate(`${healthInsurance()}/completed`)
// well-being
export const wellBeing = generate('/well-being')
export const healthTips = generate(`/well-being/health-tips`)
export const heartCare = generate(`/well-being/heart-care`)
export const healthTipsDetails = generate<{ health_tip_id: number }>(`${healthTips()}/:health_tip_id`)
export const heartCareDetails = generate<{ health_tip_id: number }>(`${heartCare()}/:health_tip_id`)
export const healthProfile = generate(`/well-being/health-profile`)
export const bloodCell = generate<{ blood_cell_id: number }>(`${healthProfile()}/blood-cell/:blood_cell_id`)

// Health Checkup
export const healthCheckup = generate('/health-checkup')
export const healthCheckupBenefits = generate<{ benefitYearId: number }>(`${healthCheckup()}/self`)
export const healthCheckupEditSelfBenefits = generate<{ benefitYearId: number }, { benefitsName: string }>(
  `${healthCheckupBenefits()}/edit`,
)

// OPD
export const opd = generate('/opd')
export const opdDetail = generate<{ opdId: number }, { isCreateNew?: 'true' | 'false' }>(`${opd()}/:opdId`)
export const opdSummary = generate<{ opdId: number }>(`${opdDetail()}/summary`)
export const opdCompleted = generate<
  { opdId: number },
  { userClaimId: number }
>(`${opdDetail()}/completed`)
export const opdHistory = generate(`${opd()}/history`)
export const opdHistoryDetail = generate<{ id: number }>(`${opdHistory()}/:id`)
